import {
  Flex,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Select,
  Grid,
  Heading,
  Spacer,
  GridItem,
  Input,
  Collapse,
  useToast,
} from "@chakra-ui/react";
import { useReducer, useState } from "react";
import { BsCloudArrowUp, BsExclamationCircle, BsXLg } from "react-icons/bs";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { toastError, toastSuccess } from "../../../Providers/ToastProvider";
import { updateCompanyMsftInfo } from "../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { CompanyMsftInfo } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";

const GRID_PROPS = {
  templateColumns: "1fr 3fr",
  columnGap: "8px",
  rowGap: "12px",
} as const;

const FLEX_GRID_ITEM_PROPS = {
  display: "flex",
  flexDir: "column",
  gap: "4px",
} as const;

export default function MsftInfoForm({
  companies,
  refreshTenants,
}: Readonly<{ companies: CompanyMsftInfo[]; refreshTenants: () => void }>) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCompany, updateSelectedCompany] = useReducer(
    (prev: CompanyMsftInfo | null, update: Partial<CompanyMsftInfo> | null) => {
      if (!update) {
        return null;
      }
      if ((!update?.name || !update?.id) && (!prev?.name || !prev?.id)) {
        return null;
      }
      if (!prev) {
        return { ...update } as CompanyMsftInfo;
      }
      return {
        ...prev,
        ...update,
      };
    },
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();

  const handleClose = (force?: boolean) => {
    if (isSubmitting && !force) {
      return;
    }
    updateSelectedCompany(null);
    onClose();
  };

  const handleSubmit = async () => {
    if (!selectedCompany) {
      return;
    }
    try {
      setIsSubmitting(true);
      const token = await getInternalApiToken();
      await updateCompanyMsftInfo(token, selectedCompany.id, {
        tenantId: selectedCompany.tenantId,
        subscriptionId: selectedCompany.subscriptionId,
        cloudInstance: selectedCompany.cloudInstance,
      });
      toastSuccess(
        toast,
        `Successfully updated Microsoft info for ${selectedCompany.name}`
      );
    } catch (error) {
      toastError(
        toast,
        `An error occurred updating Microsoft info for ${
          selectedCompany.name
        }: ${(error as Error).message || "an unknown error occurred"}`
      );
    }
    refreshTenants();
    updateSelectedCompany(null);
    handleClose(true);
    setIsSubmitting(false);
  };

  if (companies.length === 0) {
    return <></>;
  }

  return (
    <>
      <Flex marginY={4} gap="4px">
        <Button
          variant="outline"
          size="xs"
          colorScheme="orange"
          leftIcon={<BsExclamationCircle />}
          onClick={onOpen}
        >
          {companies.length} compan{companies.length > 1 ? "ies are " : "y is "}
          missing Microsoft info
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody display="flex" flexDir="column" gap={4} padding="12px">
            <Flex width="100%" flexDir="row">
              <Heading size="md">Edit Microsoft Info</Heading>
              <Spacer />
              <Button
                onClick={() => handleClose()}
                variant="ghost"
                leftIcon={<BsXLg />}
                size="xs"
              >
                Close
              </Button>
            </Flex>
            <Text fontSize="xs" as="i">
              Use this form to enter the Microsoft Tenant information for a
              customer during account setup.
            </Text>
            <Grid {...GRID_PROPS}>
              <GridItem>
                <Text fontWeight="semibold">Select Company</Text>
              </GridItem>
              <GridItem>
                <Select
                  size="sm"
                  value={selectedCompany?.id ?? ""}
                  placeholder="Select"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      updateSelectedCompany(null);
                    } else {
                      updateSelectedCompany(
                        companies.find(
                          (company) => company.id === e.target.value
                        ) ?? null
                      );
                    }
                  }}
                >
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </Select>
              </GridItem>
            </Grid>
            <Collapse in={Boolean(selectedCompany)}>
              <Grid {...GRID_PROPS}>
                <GridItem>
                  <Text fontWeight="semibold">Tenant ID</Text>
                </GridItem>
                <GridItem {...FLEX_GRID_ITEM_PROPS}>
                  <Input
                    type="text"
                    size="sm"
                    value={selectedCompany?.tenantId ?? ""}
                    onChange={(e) =>
                      updateSelectedCompany({ tenantId: e.target.value })
                    }
                  />
                  <Text as="i" fontSize="xs">
                    This can be found by navigating to the Azure Portal and
                    opening the &quot;Microsoft Entra ID&quot; page.
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="semibold">Subscription ID</Text>
                </GridItem>
                <GridItem {...FLEX_GRID_ITEM_PROPS}>
                  <Input
                    type="text"
                    size="sm"
                    value={selectedCompany?.subscriptionId ?? ""}
                    onChange={(e) =>
                      updateSelectedCompany({ subscriptionId: e.target.value })
                    }
                  />
                  <Text as="i" fontSize="xs">
                    This can be found by navigating to the Azure Portal and
                    opening the &quot;Subscriptions&quot; page. Make sure you
                    use the ID of the subscription created duing the account
                    setup process.
                  </Text>
                </GridItem>
                <GridItem>
                  <Text fontWeight="semibold">Cloud Instance</Text>
                </GridItem>
                <GridItem {...FLEX_GRID_ITEM_PROPS}>
                  <Select
                    size="sm"
                    value={selectedCompany?.cloudInstance ?? ""}
                    placeholder="Select Cloud Instance"
                    onChange={(e) =>
                      updateSelectedCompany({ cloudInstance: e.target.value })
                    }
                  >
                    <option value="gcc">GCC</option>
                    <option value="gccHigh">GCC High</option>
                  </Select>
                  <Text as="i" fontSize="xs">
                    If the Azure Portal URL for the tenant is
                    &quot;portal.azure.us&quot; then the Cloud Instance is
                    &quot;GCC High&quot; otherwise, it is &quot;GCC&quot; .
                  </Text>
                </GridItem>
              </Grid>
            </Collapse>
            <Collapse
              in={
                Boolean(selectedCompany) &&
                Object.values(selectedCompany!).every((val) => Boolean(val))
              }
            >
              <Flex flexDir="row-reverse">
                <Button
                  size="xs"
                  colorScheme="blue"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  leftIcon={<BsCloudArrowUp />}
                >
                  Submit
                </Button>
              </Flex>
            </Collapse>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
