import { Flex, Table, Th, Tr, Td, Tooltip, Text } from "@chakra-ui/react";
import { useContext } from "react";
import {
  AtomusEmailCategory,
  EmailErrors,
} from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { UserTableContext } from "../../../UserTableContext";

const getEmailErrors = (
  userEmailErrors: Record<AtomusEmailCategory, string[]>
) => {
  const emailErrorString = (
    Object.keys(userEmailErrors) as AtomusEmailCategory[]
  )
    .map(
      (key) =>
        `${key} email not delivered. Errors: ${userEmailErrors[key].join(", ")}`
    )
    .join(", ");
  return emailErrorString;
};

interface IErrorsTableProps {
  emailSendErrors: EmailErrors;
}
export default function ErrorsTable({
  emailSendErrors,
}: Readonly<IErrorsTableProps>) {
  const { usersArr } = useContext(UserTableContext);

  // get only the user ids from email errors that have errors !== {}
  // (recipients that succesfully delivered all emails have will have errors == {})
  const emailErrorUserIds = Object.keys(emailSendErrors).filter(
    (userId) => Object.keys(emailSendErrors[userId]).length !== 0
  );

  return (
    <Flex flexDir="column" padding="20px" maxHeight="210px" overflowY="auto">
      <Table>
        <Th color="gray.800">
          {emailErrorUserIds.length > 1 ? "Recipients" : "Recipient"}
        </Th>
        <Th color="gray.800">
          {emailErrorUserIds.length > 1 ? "Errors" : "Error"}
        </Th>

        {emailErrorUserIds.map((userId) => (
          <Tr key={userId}>
            <Td>
              <Text
                as="span"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                display="inline-block"
                width="140px"
                color="gray.800"
                fontSize="12pt"
              >
                {usersArr.find((user) => user.id === userId)?.displayName}
              </Text>
            </Td>
            <Td color="gray.800" fontSize="12pt">
              <Tooltip label={getEmailErrors(emailSendErrors[userId])}>
                <Text
                  as="span"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  display="inline-block"
                  width="490px"
                  color="gray.800"
                  fontSize="12pt"
                >
                  {getEmailErrors(emailSendErrors[userId])}
                </Text>
              </Tooltip>
            </Td>
          </Tr>
        ))}
      </Table>
    </Flex>
  );
}
