import { Flex, Heading } from "@chakra-ui/react";
import { Column } from "react-table";
import SortableTable from "../../../Components/SortableTable";
import { AtomusTenant } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";

const COLUMNS: Column<AtomusTenant>[] = [
  {
    Header: "Company name",
    accessor: "name",
  },
  {
    Header: "Shortform",
    accessor: "shortform",
  },
];

export default function NoMsftAccessTenants({
  noMsftAccessTenants,
}: Readonly<{ noMsftAccessTenants: AtomusTenant[] }>) {
  if (noMsftAccessTenants.length === 0) {
    return <></>;
  }
  return (
    <Flex flexDir="column" gap="8px">
      <Heading size="md">Tenants you do not have access to</Heading>
      <SortableTable
        tableProps={{
          columns: COLUMNS,
          data: noMsftAccessTenants,
          initialState: { sortBy: [{ id: "name", desc: false }] },
        }}
      />
    </Flex>
  );
}
