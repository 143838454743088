import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Checkbox,
  Flex,
  Center,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import useMicrosoftToken from "../../../../../hooks/tokens.hooks";
import {
  toastError,
  toastSuccess,
} from "../../../../../Providers/ToastProvider";
import { sendUserEmails } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { EmailErrors } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { UserTableContext } from "../UserTableContext";
import {
  useEmailTimeLines,
  useCompanyDeviceOnboardingChecks,
} from "../../../../../hooks/users.hooks";
import { useTracking } from "../../../../../hooks/tracking.hooks";
import CustomEmailOptions from "./Components/CustomEmailOptions";
import RecipientsTable from "./Components/RecipientsTable";
import ErrorsTable from "./Components/ErrorsTable";

interface ISendEmailsModalProps {
  tenantId: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function SendEmailsModal({
  tenantId,
  onClose,
  isOpen,
}: Readonly<ISendEmailsModalProps>) {
  const [sendIsLoading, setSendIsLoading] = useState<boolean>(false);
  const [emailSendErrors, setEmailSendErrors] = useState<EmailErrors>({});
  const [customOptions, setCustomOptions] = useState<boolean>(false);

  const {
    usersArr,
    selectedUserIds,
    selectedEmails,
    setSelectedEmails,
    handleEmailSelectCheckbox,
    setUserBlockedEmails,
    userBlockedEmails,
  } = useContext(UserTableContext);
  const { getInternalApiToken } = useMicrosoftToken();
  const { isLoading, isRefetching, isError } =
    useCompanyDeviceOnboardingChecks(tenantId);
  const { refetch } = useEmailTimeLines(tenantId);
  const toast = useToast();
  const { trackSendEmail } = useTracking();

  const sendIsDisabled = useMemo(() => {
    return userBlockedEmails.length !== 0;
  }, [userBlockedEmails]);

  useEffect(() => {
    setUserBlockedEmails([]);
    setEmailSendErrors({});
  }, [isOpen, setUserBlockedEmails, setEmailSendErrors]);

  const handleToggleCustomOptions = () => {
    setCustomOptions((current) => !current);
    if (customOptions) {
      // uncheck all custom emails
      setSelectedEmails((currentSelection) =>
        currentSelection.filter(
          (emailCategory) => !emailCategory.startsWith("custom-")
        )
      );
    }
  };

  const handleSendEmail = async () => {
    try {
      setSendIsLoading(true);
      const token = await getInternalApiToken();
      const res = await sendUserEmails(
        token,
        tenantId,
        selectedEmails,
        selectedUserIds
      );
      // update Segment tracking data
      for (const emailCategory of selectedEmails) {
        for (const selectedUserId of Object.keys(selectedUserIds)) {
          const recipientUser = usersArr.find(
            (user) => user.id === selectedUserId
          );
          if (recipientUser) {
            trackSendEmail(emailCategory, recipientUser?.username, tenantId);
          }
        }
      }
      refetch();
      // Find any errors
      const userErrorValues = Object.values(res.errors);
      if (!userErrorValues.every((value) => Object.keys(value).length === 0)) {
        setSendIsLoading(false);
        setEmailSendErrors(res.errors);
        toastError(
          toast,
          "Some emails failed to send, see errors section for details."
        );
      } else {
        setSendIsLoading(false);
        onClose();
        setSelectedEmails([]);
        toastSuccess(
          toast,
          `${
            selectedEmails.length > 1 || Object.keys(selectedUserIds).length > 1
              ? "Emails"
              : "Email"
          } sent!`
        );
      }
    } catch (error) {
      toastError(toast, "Error sending emails.");
      setSendIsLoading(false);
    }
  };

  useEffect(() => {
    // close modal when no selected user ids
    if (Object.keys(selectedUserIds).length === 0) {
      onClose();
    }
  }, [onClose, selectedUserIds]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent minWidth="70%">
        <ModalCloseButton />
        <ModalBody>
          <Text m="20px" fontSize="20pt">
            Send Emails
          </Text>
          <Stack spacing={3} m="30px">
            <Checkbox
              isChecked={selectedEmails.includes("user-guide")}
              onChange={() => handleEmailSelectCheckbox("user-guide")}
            >
              Intro User Guide
            </Checkbox>
            <Flex>
              <Checkbox
                isChecked={selectedEmails.includes("device-onboarding")}
                onChange={() => handleEmailSelectCheckbox("device-onboarding")}
              >
                Device Onboarding (default)
              </Checkbox>
            </Flex>

            <Checkbox
              isChecked={selectedEmails.includes("cs-training")}
              onChange={() => handleEmailSelectCheckbox("cs-training")}
            >
              Training
            </Checkbox>
            <Checkbox
              isChecked={selectedEmails.includes("employee-handbook")}
              onChange={() => handleEmailSelectCheckbox("employee-handbook")}
            >
              Employee Handbook
            </Checkbox>
            <Checkbox
              isChecked={customOptions}
              onChange={() => handleToggleCustomOptions()}
            >
              Custom Device Onboarding
            </Checkbox>
            <Flex ml="30px">{customOptions && <CustomEmailOptions />}</Flex>
          </Stack>
          <Center>
            <Box
              m="5px"
              width="90%"
              border="2px solid"
              borderColor="blue.500"
              p="5px"
              rounded="7px"
            >
              <RecipientsTable tenantId={tenantId} />
            </Box>
          </Center>
          {Object.keys(emailSendErrors).length !== 0 && (
            <Center>
              <Box
                m="5px"
                width="90%"
                border="2px solid"
                borderColor="red.500"
                p="5px"
                rounded="7px"
              >
                <ErrorsTable emailSendErrors={emailSendErrors} />
              </Box>
            </Center>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr="12px"
            onClick={handleSendEmail}
            isDisabled={
              sendIsDisabled ||
              selectedEmails.length === 0 ||
              isLoading ||
              isRefetching ||
              isError
            }
            isLoading={sendIsLoading}
          >
            Send
          </Button>
          <Button
            colorScheme="red"
            variant="outline"
            mr="12px"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
