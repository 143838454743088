import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Checkbox,
} from "@chakra-ui/react";
import { useContext } from "react";
import { UserTableContext } from "../UserTableContext";

export default function UserFilterDropdown() {
  const {
    showActiveUsers,
    setShowActiveUsers,
    showInactiveUsers,
    setShowInactiveUsers,
    showOfflineUsers,
    setShowOfflineUsers,
  } = useContext(UserTableContext);

  const menuText = () => {
    if (showActiveUsers && !showInactiveUsers && !showOfflineUsers) {
      return "Active Users Only";
    }
    if (!showActiveUsers && showInactiveUsers && !showOfflineUsers) {
      return "Inactive Users Only";
    }
    if (!showActiveUsers && !showInactiveUsers && showOfflineUsers) {
      return "Offline Users Only";
    } else if (!showActiveUsers && !showInactiveUsers && !showOfflineUsers) {
      return "None selected";
    } else if (showActiveUsers && showInactiveUsers && showOfflineUsers) {
      return "All users";
    } else {
      return "Users (multiple selected)";
    }
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        rounded="0px"
        m="10px"
        as={Button}
        size="sm"
        variant="outline"
        rightIcon={<ChevronDownIcon />}
        textColor="gray.700"
      >
        {menuText()}
      </MenuButton>
      <MenuList>
        <MenuItem>
          <Checkbox
            onChange={() => setShowActiveUsers((prev) => !prev)}
            isChecked={showActiveUsers}
          >
            Active Users
          </Checkbox>
        </MenuItem>
        <MenuItem>
          <Checkbox
            onChange={() => setShowInactiveUsers((prev) => !prev)}
            isChecked={showInactiveUsers}
          >
            Inactive Users
          </Checkbox>
        </MenuItem>
        <MenuItem>
          <Checkbox
            onChange={() => setShowOfflineUsers((prev) => !prev)}
            isChecked={showOfflineUsers}
          >
            Offline Users
          </Checkbox>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
