import {
  CircularProgress,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { BsPencil } from "react-icons/bs";
import { StateSetter } from "../../../../../../../../types/types";
import { useEffect } from "react";

function EditInputAction({
  editMode,
  isLoading,
}: Readonly<{ editMode: boolean; isLoading: boolean }>) {
  if (!editMode) {
    return (
      <Flex ml="5px">
        <BsPencil />
      </Flex>
    );
  } else if (isLoading) {
    return <CircularProgress ml="5px" isIndeterminate size="15px" />;
  } else {
    return <Text fontSize="10pt">Save</Text>;
  }
}

interface IUserAppleCredentialInputProps {
  saveAction: () => Promise<void>;
  inputValue: string | null;
  setInputValue: StateSetter<string | null>;
  placeHolder: string;
  isLoading: boolean;
  isPassword: boolean;
  isPresent: boolean;
  editMode: boolean;
  setEditMode: StateSetter<boolean>;
}

export default function UserAppleCredentialInput({
  saveAction,
  inputValue,
  setInputValue,
  placeHolder,
  isLoading,
  isPassword,
  isPresent,
  editMode,
  setEditMode,
}: Readonly<IUserAppleCredentialInputProps>) {
  const handleToggleEdit = async () => {
    if (editMode) {
      await saveAction();
    }
    setEditMode((prev) => !prev);
  };

  useEffect(() => {
    if (inputValue === "") {
      setInputValue(null);
    }
  }, [inputValue, setInputValue]);

  return (
    <InputGroup borderColor={!isPresent ? "yellow.500" : "gray"}>
      <Input
        borderWidth={!isPresent ? "2px" : "1px"}
        type={isPassword ? "password" : "text"}
        isDisabled={!editMode}
        value={inputValue ?? ""}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={inputValue ?? placeHolder}
        mb={2}
      />
      <InputRightAddon
        width="60px"
        cursor="pointer"
        borderWidth={!isPresent ? "2px" : "1px"}
        borderColor={!isPresent ? "yellow.400" : "gray.400"}
        onClick={handleToggleEdit}
      >
        <EditInputAction editMode={editMode} isLoading={isLoading} />
      </InputRightAddon>
    </InputGroup>
  );
}
