import { Input, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import SortableTh from "../../../../Components/SortableTh/SortableTh";
import {
  useDefenderIsActive,
  useFeatureIsDisabled,
} from "../../../../hooks/tenants.hooks";
import { Device } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { SortDirection } from "../../../../types/types";
import { sortStrings } from "../../../../utils/utils";
import DeviceRow from "./DeviceRow";

export interface IDevicesTableProps {
  tenantId: string;
  devices: Device[];
  modalOnOpen: () => void;
  setModalDeviceId: React.Dispatch<React.SetStateAction<string | null>>;
  mobileSection?: boolean;
}

export default function DevicesTable({
  tenantId,
  devices,
  modalOnOpen,
  setModalDeviceId,
  mobileSection,
}: Readonly<IDevicesTableProps>) {
  // we copy the devices to a new array for sorting manipulation
  const [deviceArr, setDeviceArr] = useState([...devices]);
  const [sortDir, setSortDir] = useState<SortDirection>("asc");
  const [sortKey, setSortKey] = useState<
    "displayName" | "registeredUsers" | null
  >(null);
  const [searchStr, setSearchStr] = useState<string>("");
  const { data: defenderIsActive } = useDefenderIsActive(tenantId);
  const { data: backupDisabledData } = useFeatureIsDisabled(tenantId, "backup");

  const sortDevices = (key: "displayName" | "registeredUsers") => {
    const newSortDir = key === sortKey && sortDir === "asc" ? "desc" : "asc";
    const newArr = [...deviceArr].sort((a, b) => {
      if (key === "registeredUsers") {
        const usersStrA =
          a[key]?.map((user) => user.userPrincipalName).join(", ") ?? "";
        const usersStrB =
          b[key]?.map((user) => user.userPrincipalName).join(", ") ?? "";
        return sortStrings(usersStrA, usersStrB, newSortDir);
      }
      return sortStrings(a[key], b[key], newSortDir);
    });
    setSortDir(newSortDir);
    setSortKey(key);
    setDeviceArr(newArr);
  };

  const deviceSearchMap: Record<string, string> = useMemo(() => {
    const map: Record<string, string> = {};
    deviceArr.forEach((device) => {
      if (device.id) {
        const deviceStr = [
          device.deviceId,
          device.id,
          device.displayName,
          device.deviceName,
          device.enrollmentType,
          device.operatingSystem,
          device.registeredUsers?.map(
            (user) => `${user.displayName} ${user.userPrincipalName} ${user.id}`
          ),
        ]
          .join(" ")
          .toLowerCase();
        map[device.id] = deviceStr;
      }
    });
    return map;
  }, [deviceArr]);

  const searchMatchDevices = useMemo(() => {
    if (!searchStr) return deviceArr;
    const lowerSearchStr = searchStr.toLowerCase().trim();
    return deviceArr.filter((device) =>
      deviceSearchMap[device.id]?.includes(lowerSearchStr)
    );
  }, [searchStr, deviceSearchMap, deviceArr]);

  return (
    <>
      <Input
        size="sm"
        mt="10px"
        width="200px"
        placeholder="Search..."
        onChange={(e) => setSearchStr(e.target.value)}
      />
      <Table>
        <Thead>
          <Tr>
            <SortableTh
              isSorted={sortKey === "displayName"}
              sortDir={sortDir}
              sortFunc={() => sortDevices("displayName")}
            >
              Device Name
            </SortableTh>
            <SortableTh
              isSorted={sortKey === "registeredUsers"}
              sortDir={sortDir}
              sortFunc={() => sortDevices("registeredUsers")}
            >
              User(s)
            </SortableTh>
            <Th>Enrollment Type</Th>
            {!mobileSection && <Th>Aegis Version</Th>}
            {!mobileSection && <Th>Compliance</Th>}
            {!mobileSection && backupDisabledData?.isDisabled === false && (
              <Th>Last Backup</Th>
            )}
            {!mobileSection && defenderIsActive && <Th>App Recommendations</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {searchMatchDevices.map((device, idx) => (
            <DeviceRow
              key={`${device.id}-${idx}`}
              device={device}
              modalOnOpen={modalOnOpen}
              setModalDeviceId={setModalDeviceId}
              tenantId={tenantId}
              mobileSection={mobileSection}
              defenderIsActive={defenderIsActive}
              showBackupColumn={backupDisabledData?.isDisabled === false}
            />
          ))}
        </Tbody>
      </Table>
    </>
  );
}
