import { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { useGetTokenForScopes } from "../Providers/MicrosoftTokenProvider";
import { TokenScopes } from "../services/atomus-internal-apis/atomus-internal-apis.constants";
import { TokenRequestParams } from "../types/auth.types";

interface TokenScopesSpec {
  COMMERCIAL: readonly string[];
  GOV: readonly string[];
}

export default function useMicrosoftToken() {
  const { isGov } = useContext(AuthContext);
  const getTokenForScopes = useGetTokenForScopes();

  const getMicrosoftToken = async (
    scopes: Readonly<TokenScopesSpec>,
    params?: TokenRequestParams
  ): Promise<string> => {
    return getTokenForScopes(
      isGov ? [...scopes.GOV] : [...scopes.COMMERCIAL],
      params
    );
  };

  return {
    getInternalApiToken: (params?: TokenRequestParams) =>
      getMicrosoftToken(TokenScopes.InternalApi, params),
    getArmToken: () => getMicrosoftToken(TokenScopes.Arm),
  };
}
