import { Box, Tag, Text, Tooltip } from "@chakra-ui/react";
import { useContext } from "react";
import { TenantContext } from "../TenantContext";

export default function DocumentationLinkSection() {
  const { tenant } = useContext(TenantContext);

  const documentationPageExists = !!tenant?.documentationPage;

  return (
    <Box>
      <Text fontSize="xs" fontWeight="bold">
        Atomus Documentation Url
      </Text>
      <Tooltip
        label={tenant?.documentationPage}
        isDisabled={!documentationPageExists}
      >
        <Tag
          cursor={documentationPageExists ? "pointer" : "default"}
          colorScheme={documentationPageExists ? "green" : "red"}
          size="sm"
          onClick={() => {
            if (documentationPageExists) {
              window.open(tenant?.documentationPage ?? undefined, "_blank");
            }
          }}
        >
          {documentationPageExists ? "Exists" : "Not found"}
        </Tag>
      </Tooltip>
    </Box>
  );
}
