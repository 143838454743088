import { Tr, Td, Text, IconButton } from "@chakra-ui/react";
import { BsXSquare } from "react-icons/bs";
import RecipientStatusIcon from "../RecipientStatus";
import { useContext } from "react";
import { UserTableContext } from "../../../../UserTableContext";
import { AegisUser } from "../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import {
  useAzureUserInfo,
  useCompanyDeviceOnboardingChecks,
} from "../../../../../../../../hooks/users.hooks";
import EmailSelector from "../../../../../../../../Components/EmailSelector";

interface IRecipientsTableRowProps {
  tenantId: string;
  user: AegisUser;
}

export default function RecipientsTableRow({
  tenantId,
  user,
}: Readonly<IRecipientsTableRowProps>) {
  const { selectedUserIds, setSelectedUserIds, setUserBlockedEmails } =
    useContext(UserTableContext);

  const { data: onboardingStatusData } =
    useCompanyDeviceOnboardingChecks(tenantId);
  const { data: userMsftinfo } = useAzureUserInfo(tenantId, user.id);

  const handleRemoveRecipient = (user: AegisUser) => {
    const userIdsCopy = { ...selectedUserIds };
    delete userIdsCopy[user.id];
    setSelectedUserIds(userIdsCopy);
    setUserBlockedEmails((curr) => curr.filter((userId) => userId !== user.id));
  };

  return (
    <Tr key={user.id}>
      <Td>
        <Text
          as="span"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          display="inline-block"
          width="140px"
          color="gray.800"
          fontSize="12pt"
        >
          {user.displayName}
        </Text>
      </Td>
      <Td>
        <EmailSelector
          emails={[userMsftinfo?.userPrincipalName, user.email]}
          onChange={(e) =>
            setSelectedUserIds({
              ...selectedUserIds,
              [user.id]: e.target.value,
            })
          }
        />
      </Td>
      <Td>
        <RecipientStatusIcon
          tenantId={tenantId}
          userId={user.id}
          userStatus={onboardingStatusData?.userStatuses[user.id]}
          companyStatus={{
            aegisAppConsentGranted:
              onboardingStatusData?.aegisAppConsentGranted ?? null,
            companyGroupFound: onboardingStatusData?.companyGroupFound ?? null,
          }}
          key={user.id}
        />
      </Td>
      <Td>
        <IconButton
          variant="ghost"
          icon={<BsXSquare color="red" />}
          aria-label={"remove-recipient"}
          onClick={() => handleRemoveRecipient(user)}
        />
      </Td>
    </Tr>
  );
}
