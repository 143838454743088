import { createContext, ReactNode, useMemo, useState } from "react";
import { AtomusTenant } from "../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../types/types";

export interface ITenantContext {
  tenant: AtomusTenant | null;
  setTenant: StateSetter<AtomusTenant | null>;
}

const defaultError = () => {
  throw new Error("you must wrap your component in a TenantContext");
};

const defaultValue: ITenantContext = {
  tenant: null,
  setTenant: defaultError,
};

export const TenantContext = createContext(defaultValue);

export const TenantContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [tenant, setTenant] = useState<AtomusTenant | null>(null);

  const contextValue = useMemo(() => {
    return {
      tenant,
      setTenant,
    };
  }, [tenant, setTenant]);

  return (
    <TenantContext.Provider value={contextValue}>
      {children}
    </TenantContext.Provider>
  );
};
