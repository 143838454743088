import { createContext } from "react";
import { DashboardRole } from "../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../types/types";

interface IAuthContext {
  isGov: boolean;
  setIsGov: StateSetter<boolean>;
  role: DashboardRole;
  setRole: StateSetter<DashboardRole>;
  msftUsername: string | null;
  setMsftUsername: StateSetter<string | null>;
}

const defaultValue: IAuthContext = {
  isGov: true,
  setIsGov: () => {
    throw new Error("You must wrap your component in an AuthProvider");
  },
  role: "none",
  setRole: () => {
    throw new Error("You must wrap your component in an AuthProvider");
  },
  msftUsername: null,
  setMsftUsername: () => {
    throw new Error("You must wrap your component in an AuthProvider");
  },
};

export default createContext(defaultValue);
