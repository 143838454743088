import { Box, Checkbox } from "@chakra-ui/react";
import { useContext } from "react";
import { AtomusEmailCategory } from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { UserTableContext } from "../../../UserTableContext";

export default function OnboardingOptionCheckbox({
  emailCategory,
  description,
  isDisabled,
}: Readonly<{
  emailCategory: AtomusEmailCategory;
  description: string;
  isDisabled?: boolean;
}>) {
  const { selectedEmails, handleEmailSelectCheckbox } =
    useContext(UserTableContext);
  return (
    <Box>
      <Checkbox
        isDisabled={isDisabled ?? false}
        isChecked={selectedEmails.includes(emailCategory)}
        onChange={() => handleEmailSelectCheckbox(emailCategory)}
      >
        {description}
      </Checkbox>
    </Box>
  );
}
