import { useQuery, UseQueryResult } from "react-query";
import { UsbAccessRequest } from "../services/atomus-internal-apis/atomus-internal-apis.types";
import { getUsbRequests } from "../services/atomus-internal-apis/atomus-internal-apis.service";
import useMicrosoftToken from "./tokens.hooks";

export function useUsbRequests(
  tenantId: string
): UseQueryResult<UsbAccessRequest[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<UsbAccessRequest[], Error>(
    ["usb-requests", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getUsbRequests(token, tenantId);
    },
    { refetchOnMount: true }
  );
  return usersQuery;
}
