import { useQuery, UseQueryResult } from "react-query";
import { SupportedPlatforms } from "../services/atomus-internal-apis/atomus-internal-apis.constants";
import {
  getTenantAppInfo,
  listTenants,
  getRegistryKeys,
  validateConfig,
  getTenantActivityLogCount,
  getActivityLogs,
  getTenantOnboarding,
  getOptionalFeatureAvailability,
  getBrandingImages,
  getAccountSetupAccountInfo,
  getAccountSetupStepStatuses,
  getTenantDnsStatus,
  getVpnSslStatus,
  getArcSecret,
  getDefenderStatuses,
  getFeatureIsDisabled,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AccountInfoWithOptionalLocation,
  AccountSetupStepStatuses,
  ApiActivityRow,
  AppInfoResponse,
  ArcExpirationReponseBody,
  CompanyBrandingImages,
  ConfigValidationError,
  DefenderEnrollmentStatuses,
  DnsResult,
  OnboardingsRow,
  RegKeyConfiguration,
  TenantListResponse,
  VpnSslResponse,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";
import {
  OptionalFeatureAvailability,
  OptionalFeatureId,
} from "../types/api.types";
import useMicrosoftToken from "./tokens.hooks";

export function useTenantAppInfo(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const appInfoQuery = useQuery<AppInfoResponse, Error>(
    [tenantId, "app-info"],
    async () => {
      const token = await getInternalApiToken();
      return getTenantAppInfo(token, tenantId, SupportedPlatforms.ALL);
    }
  );
  return appInfoQuery;
}

export function useTenants(): UseQueryResult<TenantListResponse, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const tenantsQuery = useQuery<TenantListResponse, Error>(
    "tenants",
    async () => {
      const token = await getInternalApiToken();
      return listTenants(token);
    }
  );
  return tenantsQuery;
}

export function useCompanyImages(
  tenantId: string
): UseQueryResult<CompanyBrandingImages, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const imagesQuery = useQuery<CompanyBrandingImages, Error>(
    ["branding-images", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getBrandingImages(token, tenantId);
    }
  );
  return imagesQuery;
}

export function useRegistryKeys(
  tenantId: string
): UseQueryResult<RegKeyConfiguration, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const keysQuery = useQuery<RegKeyConfiguration, Error>(
    ["reg-keys", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getRegistryKeys(token, tenantId);
    }
  );
  return keysQuery;
}

export function useTenantConfigValidation(
  tenantId: string
): UseQueryResult<ConfigValidationError | null, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const configValidationQuery = useQuery<ConfigValidationError | null, Error>(
    [tenantId, "config-validation"],
    async () => {
      const token = await getInternalApiToken();
      const res = await validateConfig(token, tenantId);
      return res;
    }
  );
  return configValidationQuery;
}

export function useTenantActivityLogCount(
  tenantId: string
): UseQueryResult<number, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<number, Error>(
    [tenantId, "activity-logs", "count"],
    async () => {
      const token = await getInternalApiToken();
      return await getTenantActivityLogCount(token, tenantId);
    }
  );
}

export function useTenantActivityLogs(
  tenantId: string,
  page: number,
  numResults: number
): UseQueryResult<ApiActivityRow[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<ApiActivityRow[], Error>(
    [tenantId, "activity-logs", `page-${page}`],
    async () => {
      const token = await getInternalApiToken();
      return await getActivityLogs(token, tenantId, page, numResults);
    }
  );
}

export function useTenantOnboarding(
  tenantId: string
): UseQueryResult<OnboardingsRow | null, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const fetcher = async () => {
    const token = await getInternalApiToken();
    return await getTenantOnboarding(token, tenantId);
  };
  return useQuery<OnboardingsRow | null, Error>(
    [tenantId, "onboarding"],
    fetcher
  );
}

/**
 * @description get the status of an optional feature
 * NOTE: can add username and device name when needed
 * @param featureId id of the optional feature
 * @param tenantId id of the tenant
 */
export function useOptionalFeatureStatus(
  featureId: OptionalFeatureId,
  tenantId?: string,
  deviceName?: string,
  username?: string
): UseQueryResult<OptionalFeatureAvailability, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const queryKey = [featureId, "availability", tenantId];
  if (deviceName) {
    queryKey.push(deviceName);
  }
  if (username) {
    queryKey.push(username);
  }
  return useQuery<OptionalFeatureAvailability, Error>(queryKey, async () => {
    if (!tenantId) {
      return {
        availableForDevice: false,
        availableForUser: false,
        availableForTenant: false,
      };
    }
    const token = await getInternalApiToken();
    return getOptionalFeatureAvailability(
      token,
      tenantId,
      featureId,
      deviceName,
      username
    );
  });
}

export function useAccountSetupAccountInfo(
  tenantId: string
): UseQueryResult<AccountInfoWithOptionalLocation, Error> {
  const { getInternalApiToken } = useMicrosoftToken();

  return useQuery<AccountInfoWithOptionalLocation, Error>(
    [tenantId, "account-setup", "info"],
    async () => {
      const token = await getInternalApiToken();
      return getAccountSetupAccountInfo(token, tenantId);
    }
  );
}

export function useAccountSetupStatuses(
  tenantId: string
): UseQueryResult<AccountSetupStepStatuses, Error> {
  const { getInternalApiToken } = useMicrosoftToken();

  return useQuery<AccountSetupStepStatuses, Error>(
    [tenantId, "account-setup", "statuses"],
    async () => {
      const token = await getInternalApiToken();
      return getAccountSetupStepStatuses(token, tenantId);
    }
  );
}

export function useDnsStatuses(
  tenantId: string
): UseQueryResult<DnsResult, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<DnsResult, Error>([tenantId, "dns"], async () => {
    const token = await getInternalApiToken();
    return getTenantDnsStatus(token, tenantId);
  });
}

export function useVpnSslStatus(
  tenantId: string
): UseQueryResult<VpnSslResponse, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<VpnSslResponse, Error>([tenantId, "vpn-ssl"], async () => {
    const token = await getInternalApiToken();
    return getVpnSslStatus(token, tenantId);
  });
}

export function useArcExpiration(
  tenantId: string
): UseQueryResult<ArcExpirationReponseBody, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<ArcExpirationReponseBody, Error>(
    [tenantId, "arc-expiration"],
    async () => {
      const token = await getInternalApiToken();
      return getArcSecret(token, tenantId);
    }
  );
}

export function useDefenderEnrollment(
  tenantId: string
): UseQueryResult<DefenderEnrollmentStatuses, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<DefenderEnrollmentStatuses, Error>(
    [tenantId, "defender-enrollment"],
    async () => {
      const token = await getInternalApiToken();
      return getDefenderStatuses(token, tenantId);
    }
  );
}

export function useDefenderIsActive(
  tenantId: string
): UseQueryResult<boolean, Error> {
  const { error, data, isLoading } = useDefenderEnrollment(tenantId);
  return useQuery<boolean, Error>(
    [tenantId, "defender-is-active"],
    async () => {
      if (error) {
        throw new Error(`dependent defender query error: ${error.message}`);
      }
      if (!data) {
        throw new Error("dependent defender query data is undefined");
      }
      if (data.active && (data.macOSTotal !== -1 || data.windowsTotal !== -1)) {
        return true;
      }
      return false;
    },
    {
      enabled: !isLoading,
    }
  );
}

export function useFeatureIsDisabled(
  tenantId: string,
  featureId: string
): UseQueryResult<{ isDisabled: boolean }, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery([tenantId, featureId, "is-disabled"], async () => {
    const token = await getInternalApiToken();
    return getFeatureIsDisabled(token, tenantId, featureId);
  });
}
