import { Flex, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Column } from "react-table";
import SortableTable from "../../../Components/SortableTable";
import { AtomusTenant } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";

const COLUMNS: Column<AtomusTenant>[] = [
  {
    Header: "Company name",
    accessor: "name",
  },
  {
    Header: "Shortform",
    accessor: "shortform",
  },
  {
    Header: "Default domain",
    accessor: "domain",
  },
];

export default function MappedTenants({
  mappedTenants,
}: Readonly<{ mappedTenants: AtomusTenant[] }>) {
  const navigate = useNavigate();
  if (mappedTenants.length === 0) {
    return <></>;
  }

  const navToTenant = (tenantData: AtomusTenant) => {
    navigate(`/tenants/${tenantData.tenantId}`, {
      state: {
        shortForm: tenantData.shortform,
        companyName: tenantData.name,
      },
    });
  };

  return (
    <Flex flexDir="column" gap="8px">
      <Heading size="md">Companies you can access</Heading>
      <SortableTable
        tableProps={{
          columns: COLUMNS,
          data: mappedTenants,
          initialState: { sortBy: [{ id: "name", desc: false }] },
        }}
        onClick={navToTenant}
      />
    </Flex>
  );
}
