import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ReactNode, SetStateAction, useEffect, useState } from "react";
import { MsalAuthConfigs } from "../constants/auth.constants";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { NO_REFETCH_QUERY } from "../constants/reactquery.constants";
import AuthContext from "../contexts/AuthContext";
import { DashboardRole } from "../services/atomus-internal-apis/atomus-internal-apis.types";
import { getDashboardRole } from "../services/atomus-internal-apis/atomus-internal-apis.service";
import { TokenScopes } from "../services/atomus-internal-apis/atomus-internal-apis.constants";

const queryClient = new QueryClient({
  defaultOptions: { queries: { ...NO_REFETCH_QUERY, retry: false } },
});

const GOV_INSTANCE = new PublicClientApplication(MsalAuthConfigs.gov);
const COMMERCIAL_INSTANCE = new PublicClientApplication(
  MsalAuthConfigs.commercial
);

export default function AuthProvider({ children }: { children: ReactNode }) {
  const [isGov, _setIsGov] = useState<boolean>(true);
  const [instance, setInstance] = useState(GOV_INSTANCE);
  const [isInitialized, setIsInitialized] = useState(false);
  const [role, setRole] = useState<DashboardRole>("none");
  const [msftUsername, setMsftUsername] = useState<string | null>(null);

  useEffect(() => {
    Promise.all([
      COMMERCIAL_INSTANCE.initialize(),
      GOV_INSTANCE.initialize(),
    ]).then(() => setIsInitialized(true));
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    const { pathname } = new URL(window.location.href);
    const navToLogin = () => {
      instance.setActiveAccount(null);
      const location = new URL(window.location.href);
      location.pathname = "/login";
      window.location.href = location.toString();
    };
    const account = instance.getActiveAccount();

    if (!account && pathname !== "/login") {
      navToLogin();
      return;
    }

    if (role === "none" && pathname !== "/login" && account) {
      instance
        .acquireTokenSilent({
          scopes: isGov
            ? [...TokenScopes.InternalApi.GOV]
            : [...TokenScopes.InternalApi.COMMERCIAL],
        })
        .then(({ accessToken }) => getDashboardRole(accessToken))
        .then((newRole) => {
          if (newRole === "none") {
            navToLogin();
            return;
          }
          setRole(newRole);
          setMsftUsername(account.username);
        })
        .catch((error) => {
          console.error(error);
          navToLogin();
        });
    }
  }, [role, instance, isInitialized, isGov]);

  const setIsGov = (target: SetStateAction<boolean>) => {
    if (target !== isGov) {
      queryClient.clear();
      queryClient.removeQueries();
      _setIsGov(target);
      setInstance(target ? GOV_INSTANCE : COMMERCIAL_INSTANCE);
    }
  };

  if (!isInitialized && window.location.pathname !== "/login") {
    return <></>;
  }

  return (
    <AuthContext.Provider
      value={{
        isGov,
        setIsGov,
        role,
        setRole,
        msftUsername,
        setMsftUsername,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <MsalProvider instance={instance}>{children}</MsalProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}
