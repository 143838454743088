import { createContext, ReactNode, useState, useMemo } from "react";
import { AegisUser } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../../types/types";

export interface IUserTableContext {
  aegisUser: AegisUser | null;
  setAegisUser: StateSetter<AegisUser | null>;
}

const defaultError = () => {
  throw new Error("you must wrap your component in an UserTableContext");
};

const defaultValue: IUserTableContext = {
  aegisUser: null,
  setAegisUser: defaultError,
};

export const UserRowContext = createContext(defaultValue);

export const UserRowContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [aegisUser, setAegisUser] = useState<AegisUser | null>(null);

  const contextValue = useMemo(() => {
    return {
      aegisUser,
      setAegisUser,
    };
  }, [aegisUser, setAegisUser]);

  return (
    <UserRowContext.Provider value={contextValue}>
      {children}
    </UserRowContext.Provider>
  );
};
