import { useContext, useEffect, useState } from "react";
import {
  Flex,
  IconButton,
  Text,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Radio,
  RadioGroup,
  Stack,
  PopoverArrow,
  Tooltip,
  Box,
  CircularProgress,
  Center,
  useToast,
} from "@chakra-ui/react";
import { BsPersonCircle, BsPencil, BsExclamationCircle } from "react-icons/bs";
import {
  AegisUser,
  Device,
  RegisteredUsersData,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { useAegisUsers } from "../../../../hooks/users.hooks";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import { updateDevicePrimaryUser } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { toastSuccess, toastError } from "../../../../Providers/ToastProvider";
import { IntuneEnrollmentTypes } from "../../../../constants/microsoft.constants";
import { DevicesSectionContext } from "../DevicesSectionContext";

export default function PrimaryUserSection({
  device,
  tenantId,
}: Readonly<{ device: Device; tenantId: string }>) {
  const [hovered, setHovered] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedUserMsftId, setSelectedUserMsftId] = useState<string>();
  const [currentUser, setCurrentUser] = useState<{
    id: string;
    displayName: string;
  } | null>(device.registeredUsers?.at(0) ?? null);

  const { data, isLoading, isError } = useAegisUsers(tenantId);
  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();

  const { devices, setDevices } = useContext(DevicesSectionContext);

  const handleUpdatePrimaryUser = async () => {
    setButtonIsLoading(true);
    try {
      if (selectedUserMsftId && device.managedDeviceId) {
        const token = await getInternalApiToken();
        await updateDevicePrimaryUser(
          token,
          tenantId,
          device.managedDeviceId,
          selectedUserMsftId
        );

        const selectedDbUser: AegisUser | null =
          data?.find((aegisUser) => aegisUser.cloudId === selectedUserMsftId) ??
          null;
        setCurrentUser({
          id: selectedDbUser?.cloudId ?? "N/A",
          displayName: `${selectedDbUser?.displayName ?? "N/A"} (updating)`,
        });
        const updatedDevices = [
          ...(devices?.filter(
            (currDevice) => currDevice.deviceId !== device.deviceId
          ) ?? []),
          {
            ...device,
            registeredUsers: [
              {
                displayName: selectedDbUser?.displayName,
                userPrincipalName: selectedDbUser?.email,
                id: selectedDbUser?.cloudId,
                existsInDB: !!selectedDbUser,
                active: selectedDbUser?.active,
              } as RegisteredUsersData,
            ],
          },
        ];
        setDevices(updatedDevices);

        toastSuccess(
          toast,
          "Updated primary user (note: this change may take some time to propagate)"
        );
      } else {
        throw new Error("An unknown error has occurred");
      }
    } catch (error) {
      toastError(
        toast,
        "Failed to update primary user. Please contact success@atomuscyber.com if the issue persists"
      );
    }
    setButtonIsLoading(false);
  };

  useEffect(() => {
    if (
      device.managedDeviceId &&
      device.enrollmentType === IntuneEnrollmentTypes.DOMAIN_JOINED
    ) {
      setIsDisabled(false);
    }
  }, [device.enrollmentType, device.managedDeviceId]);

  return (
    <VStack align="start">
      {currentUser && (
        <Flex align="center">
          <Popover>
            <PopoverTrigger>
              {isError ? (
                <Tooltip label="Unable to load users selection">
                  <Box m="10px">
                    <BsExclamationCircle />
                  </Box>
                </Tooltip>
              ) : (
                <Box>
                  <Tooltip
                    isDisabled={!isDisabled}
                    label="Cannot edit primary user for this device"
                  >
                    <IconButton
                      isDisabled={isDisabled}
                      icon={hovered ? <BsPencil /> : <BsPersonCircle />}
                      aria-label="Profile Icon"
                      variant="ghost"
                      isRound
                      onMouseEnter={() => setHovered(true)}
                      onMouseLeave={() => setHovered(false)}
                      m="10px"
                    />
                  </Tooltip>
                </Box>
              )}
            </PopoverTrigger>

            <PopoverContent width="260px">
              <PopoverArrow />
              {isLoading && (
                <Center m="20px">
                  <CircularProgress isIndeterminate size="30px" />
                </Center>
              )}
              {data && (
                <PopoverBody>
                  <Text fontWeight="semibold" fontSize="12pt" mb="10px">
                    Change Primary User
                  </Text>
                  <RadioGroup
                    onChange={(e) => setSelectedUserMsftId(e)}
                    value={selectedUserMsftId}
                  >
                    <Stack direction="column">
                      {data
                        .filter(
                          (aegisUser) =>
                            aegisUser.cloudId !== null &&
                            aegisUser.cloudId !== currentUser.id
                        )
                        .map((aegisUser) => (
                          <Radio key={aegisUser.id} value={aegisUser.cloudId!}>
                            {aegisUser.displayName}
                          </Radio>
                        ))}
                    </Stack>
                  </RadioGroup>
                  <Flex flexDir="row-reverse" mt="10px">
                    <Button
                      size="sm"
                      isLoading={buttonIsLoading}
                      colorScheme="blue"
                      onClick={() => handleUpdatePrimaryUser()}
                    >
                      Save
                    </Button>
                  </Flex>
                </PopoverBody>
              )}
            </PopoverContent>
          </Popover>
          <Text>{currentUser.displayName}</Text>
        </Flex>
      )}
    </VStack>
  );
}
