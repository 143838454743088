import { useMsal } from "@azure/msal-react";
import { Center } from "@chakra-ui/react";
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import LoadingBar from "../../Components/LoadingBar";
import Page from "../../Components/PageContainer";
import AuthContext from "../../contexts/AuthContext";
import SecurityAdminTenantsPage from "./SecurityAdminTenantsPage/SecurityAdminTenantsPage";

export default function TenantsListPage() {
  const { role } = useContext(AuthContext);
  const { instance } = useMsal();

  if (role === "none") {
    return (
      <Page>
        <LoadingBar />
      </Page>
    );
  }

  if (role === "companyAdmin") {
    const tenantId = instance.getActiveAccount()?.tenantId;
    if (!tenantId) {
      return (
        <Page>
          <Center color="red">
            Unable to get tenant id for active account
          </Center>
        </Page>
      );
    }
    return (
      <Navigate to={`/tenants/${instance.getActiveAccount()?.tenantId}`} />
    );
  }

  return <SecurityAdminTenantsPage />;
}
