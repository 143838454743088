import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Heading,
  Button,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";

interface IConfirmationModalProps {
  friendlyName?: string;
  platform: "MacOS" | "Windows";
  isOpen: boolean;
  handleConfirm: () => Promise<void>;
  onClose: () => void;
  approve: boolean;
}

export default function ConfirmationModal({
  friendlyName,
  platform,
  isOpen,
  handleConfirm,
  onClose,
  approve,
}: Readonly<IConfirmationModalProps>) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding="28px">
          <Flex flexDir="column" gap="16px" alignItems="center">
            <Heading size="md" fontWeight="semibold" fontSize="20pt">
              <Text align="center"> {friendlyName ?? "unknown device"}</Text>
            </Heading>
            <Text fontSize="13pt" align="left">
              Are you sure you want to{" "}
              <Text as="b">{approve ? "approve" : "block"} </Text>
              {friendlyName ?? "unknown device"}? This will{" "}
              {approve ? "allow" : "prevent"} usage of this usb for all{" "}
              {platform} devices in the organization.
            </Text>
            <Flex flexDir="row" gap="12px" mt="10px">
              <Button
                size="sm"
                isLoading={isLoading}
                colorScheme="blue"
                variant="outline"
                leftIcon={<BsCheckLg />}
                onClick={async () => {
                  setIsLoading(true);
                  await handleConfirm();
                  onClose();
                  setIsLoading(false);
                }}
              >
                Confirm
              </Button>
              <Button
                isDisabled={isLoading}
                size="sm"
                variant="outline"
                colorScheme="red"
                leftIcon={<BsXLg />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
