import { Flex, Spacer, Text, Box } from "@chakra-ui/react";
import { IntuneDeviceOs } from "../../../../../constants/microsoft.constants";
import { Device } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import DeviceActions from "./DeviceActions";
import { useRef } from "react";
import PrimaryUserSection from "../PrimaryUserSection";

export default function DeviceHeader({
  device,
  tenantId,
  onClose,
}: Readonly<{ device: Device; tenantId: string; onClose: () => void }>) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Flex
        flexDir="column"
        alignContent="flex-start"
        ml="30px"
        mt="40px"
        width="300px"
      >
        <Box display="flex" alignItems="center" ref={inputRef}>
          <Text fontSize="18pt" width="300px" ml="10px">
            {device.displayName}
          </Text>
        </Box>
      </Flex>
      <Flex flexDir="column" mt="27px">
        <PrimaryUserSection device={device} tenantId={tenantId} />
      </Flex>
      <Spacer />
      <Flex gap="12px" mt="43px" mr="20px">
        {(device.operatingSystem === IntuneDeviceOs.MAC ||
          device.operatingSystem === IntuneDeviceOs.WINDOWS) && (
          <DeviceActions
            device={device}
            onClose={onClose}
            tenantId={tenantId}
          />
        )}
      </Flex>
    </>
  );
}
