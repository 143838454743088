import {
  Flex,
  Text,
  Spacer,
  Tag,
  useDisclosure,
  Tooltip,
  Skeleton,
  Button,
} from "@chakra-ui/react";
import { AegisUser } from "../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { useContext } from "react";
import { UserTableContext } from "../../../../UserTableContext";
import { useTenantId } from "../../../../../../../../hooks/utils.hooks";
import {
  useCheckrCandidates,
  useCheckrReportStatus,
} from "../../../../../../../../hooks/integrations.hooks";
import CheckrCandidatesModal from "./CheckrCandidatesModal";
import { CHECKR_COMPLETE } from "../../../../../../../../constants/integrations.constants";

export default function CheckrSection({
  aegisUser,
}: Readonly<{ aegisUser: AegisUser }>) {
  const { checkrConnectionStatus } = useContext(UserTableContext);

  const tenantId = useTenantId();
  const { data, isError, error } = useCheckrReportStatus(
    tenantId,
    aegisUser.checkrId
  );
  const { data: checkrCandidatesData, isError: checkrCandidatesIsError } =
    useCheckrCandidates(tenantId);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getCheckrColorScheme = () => {
    if (data?.backgroundCheckStatus === CHECKR_COMPLETE) {
      return "green";
    } else {
      return "gray";
    }
  };

  const bgCheckTooltipMessage = isError
    ? error.message
    : `Last updated: ${
        data?.lastPulledAt
          ? new Date(data?.lastPulledAt).toLocaleDateString("en-us", {
              year: "numeric",
              day: "2-digit",
              month: "short",
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })
          : "Never"
      }`;

  if (!checkrConnectionStatus) {
    return <></>;
  }

  return (
    <>
      <CheckrCandidatesModal
        aegisUser={aegisUser}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex marginY="8px">
        <Text>Background check:</Text>
        <Spacer />
        {aegisUser.checkrId ? (
          <Tooltip label={bgCheckTooltipMessage}>
            <Skeleton isLoaded={!!data || isError}>
              <Tag colorScheme={getCheckrColorScheme()}>
                {data?.backgroundCheckStatus ?? "n/a"}
              </Tag>
            </Skeleton>
          </Tooltip>
        ) : (
          <Tooltip
            isDisabled={
              !(
                checkrCandidatesData === undefined ||
                checkrCandidatesData?.length === 0
              )
            }
            label={
              checkrCandidatesIsError
                ? "Error fetching Checkr candidates"
                : "All Checkr candidates have been mapped"
            }
          >
            <Tag
              colorScheme="blue"
              variant="outline"
              as={Button}
              h="20px"
              cursor="pointer"
              onClick={onOpen}
              ml="8px"
              isDisabled={
                checkrCandidatesData?.length === 0 || checkrCandidatesIsError
              }
            >
              Map Candidate
            </Tag>
          </Tooltip>
        )}
      </Flex>
    </>
  );
}
