import {
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { BsApple } from "react-icons/bs";
import useMicrosoftToken from "../../../../../../../hooks/tokens.hooks";
import { useCompanyDeviceOnboardingChecks } from "../../../../../../../hooks/users.hooks";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "../../../../../../../Providers/ToastProvider";
import { updateAppleCredentials } from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { UserRowContext } from "../../UserRowContext";
import UserAppleCredentialInput from "./Components/UserAppleCredentialInput";

type IUserAppleOptionsProps = Readonly<{
  tenantId: string;
}>;

export default function UserAppleOptions({ tenantId }: IUserAppleOptionsProps) {
  const { getInternalApiToken } = useMicrosoftToken();
  const { refetch } = useCompanyDeviceOnboardingChecks(tenantId);
  const toast = useToast();
  const { aegisUser } = useContext(UserRowContext);

  const [appleId, setAppleId] = useState<string | null>(null);
  const [appleTempPass, setAppleTempPass] = useState<string | null>(null);
  const [editAppleId, setEditAppleId] = useState<boolean>(false);
  const [editAppleTempPass, setEditAppleTempPass] = useState<boolean>(false);
  const [appleIdIsLoading, setAppleIdIsLoading] = useState<boolean>(false);
  const [applePassIsLoading, setApplePassIsLoading] = useState<boolean>(false);

  const [updated, setUpdated] = useState(false);

  const handlePopoverClose = () => {
    if (aegisUser) {
      if (
        (appleId !== aegisUser.appleId || appleTempPass !== null) &&
        !updated
      ) {
        toastInfo(toast, "Changes discarded");
      }
      setUpdated(false);
      setEditAppleId(false);
      setEditAppleTempPass(false);
      setAppleId(aegisUser?.appleId);
      setAppleTempPass(null);
    }
  };

  useEffect(() => {
    if (aegisUser?.appleId) {
      setAppleId(aegisUser?.appleId ?? null);
    }
  }, [aegisUser?.appleId, aegisUser?.appleTempPass]);

  const handleUpdateAppleCredentials = async (field: "id" | "password") => {
    field === "id" ? setAppleIdIsLoading(true) : setApplePassIsLoading(true);
    try {
      if (appleId && !(appleId.includes(".") && appleId.includes("@"))) {
        toastError(toast, "Apple Id must be a vaild email");
      } else if (aegisUser) {
        const token = await getInternalApiToken();
        // update credentials
        const res = await updateAppleCredentials(
          token,
          tenantId,
          aegisUser?.id,
          field === "id" ? appleId : null,
          field === "password" ? appleTempPass : null
        );
        // update this user's status
        aegisUser.appleTempPass = res.appleTempPass;
        aegisUser.appleId = res.appleId;
        // refetch the ready-to-onboard status
        refetch();
        setUpdated(true);
        toastSuccess(
          toast,
          field === "id" ? "Updated Apple Id" : "Updated Apple Temp Pass"
        );
      } else {
        // aegis user is nullish
        throw new Error();
      }
    } catch (error) {
      toastError(
        toast,
        "Error updating user's Apple Credentials. Please contact success@atomuscyber.com if the issue persists."
      );
    }
    if (field === "id") {
      setAppleIdIsLoading(false);
    } else {
      setApplePassIsLoading(false);
      setAppleTempPass(null);
    }
  };

  return (
    <Popover onClose={handlePopoverClose}>
      <PopoverTrigger>
        <IconButton
          aria-label={"credentials"}
          variant="ghost"
          icon={<BsApple />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton m="5px" />

        <PopoverBody>
          <Text m="10px">Apple Credentials for onboarding instructions</Text>
          <UserAppleCredentialInput
            saveAction={() => handleUpdateAppleCredentials("id")}
            isPassword={false}
            inputValue={appleId}
            isLoading={appleIdIsLoading}
            isPresent={!!aegisUser?.appleId}
            placeHolder="Apple Id"
            setInputValue={setAppleId}
            editMode={editAppleId}
            setEditMode={setEditAppleId}
          />
          <UserAppleCredentialInput
            saveAction={() => handleUpdateAppleCredentials("password")}
            isPassword
            inputValue={appleTempPass}
            isLoading={applePassIsLoading}
            isPresent={aegisUser?.appleTempPass || appleTempPass !== null}
            placeHolder={
              aegisUser?.appleTempPass
                ? "(password set)"
                : "Apple Temporary Password"
            }
            setInputValue={setAppleTempPass}
            editMode={editAppleTempPass}
            setEditMode={setEditAppleTempPass}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
