import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Skeleton,
  Spacer,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { BsEnvelope, BsWindow } from "react-icons/bs";
import SortableTh from "../../../../Components/SortableTh/SortableTh";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import { useDeviceAppRecommendations } from "../../../../hooks/devices.hooks";
import { useDefenderIsActive } from "../../../../hooks/tenants.hooks";
import useMicrosoftToken from "../../../../hooks/tokens.hooks";
import {
  useAegisUsers,
  useEmailTimeLines,
} from "../../../../hooks/users.hooks";
import { useDataSorting } from "../../../../hooks/utils.hooks";
import { toastError, toastSuccess } from "../../../../Providers/ToastProvider";
import { sendDefenderAppRecsEmail } from "../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AegisUser,
  AppRecommendationRemediationType,
  Device,
  RegisteredUsersData,
  SecurityRecommendation,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

function AppRecommendationType({
  type,
}: Readonly<{
  type: AppRecommendationRemediationType | null;
}>) {
  let color: string;
  if (type === "ConfigurationChange") {
    color = "blue";
  } else if (type === "Uninstall") {
    color = "red";
  } else if (type === "Update") {
    color = "yellow";
  } else if (type === "Upgrade") {
    color = "purple";
  } else {
    color = "gray";
  }
  return <Tag colorScheme={color}>{type ?? "Unknown"}</Tag>;
}

function EmailOptions({
  registeredUsers,
  aegisUsers,
}: Readonly<{
  registeredUsers: RegisteredUsersData[];
  aegisUsers?: AegisUser[];
}>) {
  const associatedAegisUserEmails = [];
  for (const registeredUser of registeredUsers) {
    const matchingAegisUser = aegisUsers?.find(
      (aegisUser) => registeredUser.id === aegisUser.cloudId
    );
    const matchingAegisUserEmail = matchingAegisUser?.email;
    if (
      matchingAegisUserEmail &&
      matchingAegisUserEmail.toLowerCase() !==
        registeredUser.userPrincipalName.toLowerCase()
    ) {
      associatedAegisUserEmails.push(matchingAegisUserEmail);
    }
  }
  return (
    <>
      <option disabled key="placeholder" value="">
        Select Recipient
      </option>
      {registeredUsers.map((user) => (
        <option key={user.id} value={user.userPrincipalName}>
          {user.userPrincipalName}
        </option>
      ))}
      {associatedAegisUserEmails.map((email) => (
        <option key={email} value={email}>
          {email}
        </option>
      ))}
    </>
  );
}

export default function SecurityRecommendationsSection({
  device: { deviceId, id, deviceName, registeredUsers },
  tenantId,
}: Readonly<{
  device: Required<
    Pick<Device, "id" | "deviceId" | "deviceName" | "registeredUsers">
  >;
  tenantId: string;
}>) {
  const {
    isLoading: activeLoading,
    error: activeError,
    data: activeData,
  } = useDefenderIsActive(tenantId);
  const { isRefetching, data, error, refetch, isLoading } =
    useDeviceAppRecommendations(tenantId);
  const thisDeviceRecommendations = useMemo<
    | Pick<
        SecurityRecommendation,
        | "id"
        | "recommendationName"
        | "recommendationCategory"
        | "remediationType"
        | "relatedComponent"
      >[]
    | null
  >(() => data?.[deviceId] ?? null, [data, deviceId]);

  const { activeItems, handleSort, sortDir, sortKey } = useDataSorting(
    thisDeviceRecommendations ?? undefined
  );

  const [refreshWasClicked, setRefreshWasClicked] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { getInternalApiToken } = useMicrosoftToken();
  const [emailIsSending, setEmailIsSending] = useState(false);
  const toast = useToast();
  const { refetch: emailsRefetch } = useEmailTimeLines(tenantId);
  const { data: aegisUsers } = useAegisUsers(tenantId);
  const [recipientEmail, setRecipientEmail] = useState<string | null>(
    registeredUsers.at(0)?.userPrincipalName ?? null
  );

  useEffect(() => {
    if (isRefetching && refreshWasClicked) {
      setIsRefreshing(true);
    } else if (!isRefetching && refreshWasClicked) {
      setIsRefreshing(false);
      setRefreshWasClicked(false);
    }
  }, [isRefetching, refreshWasClicked]);

  const handleSendEmail = async () => {
    try {
      setEmailIsSending(true);
      const token = await getInternalApiToken();
      if (recipientEmail) {
        await sendDefenderAppRecsEmail(token, tenantId, {
          deviceId: id,
          deviceObjectId: deviceId,
          deviceName,
          recipientEmail,
        });
      } else {
        throw new Error("A recipient must be specified");
      }
      toastSuccess(toast, "Successfully sent notification email");
    } catch (error) {
      console.error(error);
      toastError(toast, "Error sending notification email");
    }
    setEmailIsSending(false);
    emailsRefetch();
  };

  if (activeLoading || activeData === false) {
    // dont show unless defender has been setup
    return <></>;
  }

  let content: ReactNode;
  if (
    isLoading ||
    isRefreshing ||
    (thisDeviceRecommendations &&
      thisDeviceRecommendations.length > 0 &&
      thisDeviceRecommendations.length !== activeItems.length)
  ) {
    content = <Skeleton width="25%" height="1.5em" />;
  } else if (error || (activeError && !data && !isLoading)) {
    content = (
      <Box>
        <TooltipTag
          tagText="Error"
          tooltipText={
            error?.message ??
            activeError?.message ??
            "an unknown error occurred"
          }
          tagColorScheme="red"
        />
      </Box>
    );
  } else if (thisDeviceRecommendations === null) {
    content = (
      <Box>
        <TooltipTag
          tagText="Defender device not found"
          tooltipText="This likely means the device has not been enrolled in defender"
          tagColorScheme="red"
        />
      </Box>
    );
  } else if (thisDeviceRecommendations?.length === 0) {
    content = (
      <Box width="fit-content">
        <Tag colorScheme="green">No application security recommendations</Tag>
      </Box>
    );
  } else {
    content = (
      <Flex flexDir="column" gap="12px">
        <Flex gap="12px">
          <Tag colorScheme="yellow">
            <Flex gap="12px" alignItems="center">
              <BsWindow />
              {activeItems.length} application security recommendations found
            </Flex>
          </Tag>
          <Popover>
            <PopoverTrigger>
              <Button
                size="xs"
                variant="outline"
                colorScheme="blue"
                leftIcon={<BsEnvelope />}
                isLoading={emailIsSending}
              >
                Notify user
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Select
                  onChange={(e) => setRecipientEmail(e.target.value ?? null)}
                  placeholder=""
                  width="100%"
                  color="gray.800"
                  fontSize="12pt"
                  variant="outline"
                >
                  <EmailOptions
                    registeredUsers={registeredUsers}
                    aegisUsers={aegisUsers}
                  />
                </Select>
                <Button
                  mt="10px"
                  width="100%"
                  colorScheme="blue"
                  variant="outline"
                  height="25px"
                  size="sm"
                  onClick={handleSendEmail}
                >
                  Send
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton paddingLeft="0">
              <Flex width="100%" alignItems="center">
                <Text>Details</Text>
                <Spacer />
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              <Table>
                <Thead>
                  <Tr>
                    <SortableTh
                      isSorted={sortKey === "recommendationName"}
                      sortDir={sortDir}
                      sortFunc={() => handleSort("recommendationName")}
                    >
                      Description
                    </SortableTh>
                    <SortableTh
                      isSorted={sortKey === "relatedComponent"}
                      sortDir={sortDir}
                      sortFunc={() => handleSort("relatedComponent")}
                    >
                      Application
                    </SortableTh>
                    <SortableTh
                      isSorted={sortKey === "remediationType"}
                      sortDir={sortDir}
                      sortFunc={() => handleSort("remediationType")}
                    >
                      Remediation Type
                    </SortableTh>
                  </Tr>
                </Thead>
                <Tbody>
                  {activeItems.map((rec) => (
                    <Tr key={rec.id}>
                      <Td>{rec.recommendationName}</Td>
                      <Td>{rec.relatedComponent}</Td>
                      <Td>
                        <AppRecommendationType
                          type={rec.remediationType ?? null}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" gap="8px">
      <Flex alignItems="baseline" gap="12px">
        <Text fontSize="16pt" mb="10px">
          Application Security Recommendations
        </Text>
        <Spacer />
        <Button
          size="xs"
          variant="ghost"
          onClick={() => {
            setRefreshWasClicked(true);
            refetch({ throwOnError: true });
          }}
        >
          Refresh
        </Button>
      </Flex>
      <Flex mx="10px" flexDir="column" gap="8px">
        {content}
      </Flex>
    </Flex>
  );
}
