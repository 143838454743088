import {
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Flex,
  useToast,
  Select,
  Text,
  Button,
  Box,
  Tooltip,
  CircularProgress,
  Center,
  useOutsideClick,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { BsDashCircle, BsLaptop } from "react-icons/bs";
import useMicrosoftToken from "../../../../../../../../hooks/tokens.hooks";
import {
  useAegisUsersDevices,
  useCompanyDeviceOnboardingChecks,
} from "../../../../../../../../hooks/users.hooks";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../../Providers/ToastProvider";
import { addDevicesForAegisUsers } from "../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  DeviceOS,
  DeviceOSValues,
} from "../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { UserRowContext } from "../../../UserRowContext";
import DeviceGrid from "./DeviceGrid";

const deviceOSOptions = Object.values(DeviceOSValues) as DeviceOS[];

type IUserDeviceOptionsProps = Readonly<{
  tenantId: string;
}>;

export default function UserDeviceOptions({
  tenantId,
}: IUserDeviceOptionsProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [devicesToAdd, setDevicesToAdd] = useState<(DeviceOS | undefined)[]>(
    []
  );
  const { onClose, isOpen, onToggle } = useDisclosure();
  const popoverRef = useRef(null);
  const [showAddSection, setShowAddSection] = useState<boolean>(false);
  const { aegisUser } = useContext(UserRowContext);
  const { getInternalApiToken } = useMicrosoftToken();

  const toast = useToast();

  const { data, isFetching, refetch } = useAegisUsersDevices(tenantId);
  const { refetch: readyToOnboardRefetch } =
    useCompanyDeviceOnboardingChecks(tenantId);

  /**
   * @description make the api call to add the selected devices for this user
   */
  const handleAddDevices = async () => {
    setIsLoading(true);
    try {
      const token = await getInternalApiToken();
      if (aegisUser) {
        // make absolute sure there are no undefined values
        const definedDevices = devicesToAdd.filter(
          (device) => device !== undefined
        ) as DeviceOS[];
        // make API call
        await addDevicesForAegisUsers(
          token,
          tenantId,
          aegisUser?.id,
          definedDevices
        );
      }
      toastSuccess(toast, `Added devices for ${aegisUser?.displayName}`);
      // refetch for devices we just added
      await refetch();
      // refetch ready-to-onboard status with db devices now
      readyToOnboardRefetch();
      setDevicesToAdd([]);
      setShowAddSection(false);
    } catch (error) {
      toastError(
        toast,
        "Error adding user devices. Please contact success@atomuscyber.com if this issue persists."
      );
    }
    setIsLoading(false);
  };

  useOutsideClick({
    // reset popover if clicked away
    ref: popoverRef,
    handler: () => {
      onClose();
      setDevicesToAdd([]);
    },
  });

  useEffect(() => {
    if (devicesToAdd.length === 0) {
      setShowAddSection(false);
    }
  }, [devicesToAdd]);

  const thisUserDevices = useMemo(() => {
    // get the devices from the query for this user
    if (data && aegisUser) {
      return data[aegisUser.id] ?? null;
    }
  }, [data, aegisUser]);

  const allOptionsSelected = useMemo(() => {
    // boolean indicating whether the buttons should be disabled (if all device OS options
    // are selected or already exist)
    return (
      [...devicesToAdd, ...(thisUserDevices ?? [])].length ===
      Object.keys(DeviceOSValues).length
    );
  }, [devicesToAdd, thisUserDevices]);

  return (
    <Popover placement="top" onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <IconButton
          aria-label={"credentials"}
          variant="ghost"
          icon={<BsLaptop />}
          onClick={onToggle}
        />
      </PopoverTrigger>
      <PopoverContent padding="10px" ref={popoverRef}>
        <PopoverArrow />
        <PopoverCloseButton m="5px" />

        <PopoverBody>
          <Box width="220px" overflow="hidden">
            <Text
              align="left"
              fontSize="12pt"
              ml="15px"
              mb="10px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Onboarding instruction devices for {aegisUser?.displayName}
            </Text>
          </Box>
          <Box
            minHeight="30px"
            maxHeight="200px"
            overflow="scroll"
            m="15px"
            width="90%"
            border="2px solid"
            borderColor="blue.500"
            p="5px"
            rounded="7px"
          >
            {isFetching ? (
              <Center>
                <CircularProgress isIndeterminate padding="5px" size="20px" />
              </Center>
            ) : (
              <DeviceGrid
                allOptionsSelected={allOptionsSelected}
                setDevicesToAdd={setDevicesToAdd}
                setShowAddSection={setShowAddSection}
                thisUserDevices={thisUserDevices}
              />
            )}
          </Box>
          {showAddSection && (
            <>
              <Box
                maxHeight="300px"
                overflow="scroll"
                width="90%"
                border="2px solid"
                borderColor="blue.500"
                p="5px"
                rounded="7px"
                minHeight="30px"
                m="15px"
              >
                {devicesToAdd.map((deviceOsToAdd, deviceIndex) => {
                  return (
                    <Flex key={deviceOsToAdd}>
                      <Select
                        mr="5px"
                        p="5px"
                        key={deviceOsToAdd}
                        value={devicesToAdd[deviceIndex] ?? ""}
                        onChange={(e) => {
                          const updatedDevices = [...devicesToAdd];
                          updatedDevices[deviceIndex] = e.target
                            .value as DeviceOS;
                          setDevicesToAdd(updatedDevices);
                        }}
                      >
                        <option value="" disabled>{`OS for device #${
                          deviceIndex + 1
                        }`}</option>
                        {deviceOSOptions.map((deviceOs) => (
                          <option
                            key={deviceOs}
                            value={deviceOs}
                            disabled={
                              thisUserDevices?.some(
                                (thisDevice) => thisDevice.os === deviceOs
                              ) || devicesToAdd.includes(deviceOs)
                            }
                          >
                            {deviceOs}
                          </option>
                        ))}
                      </Select>
                      <IconButton
                        mt="5px"
                        paddingBottom="2px"
                        isDisabled={devicesToAdd.length === 0}
                        variant="ghost"
                        icon={<BsDashCircle color="red" />}
                        aria-label={"remove-device"}
                        onClick={() => {
                          const updatedDevices = [...devicesToAdd];
                          setDevicesToAdd([
                            ...updatedDevices.slice(0, deviceIndex),
                            ...updatedDevices.slice(deviceIndex + 1),
                          ]);
                        }}
                      />
                    </Flex>
                  );
                })}
              </Box>
              <Flex flexDir="row-reverse" mr="15px">
                <Tooltip
                  label={
                    devicesToAdd.some((device) => device === undefined)
                      ? "Must select OS for all devices before submitting"
                      : "Submit devices to include in default device onboarding email"
                  }
                >
                  <Button
                    colorScheme="blue"
                    onClick={handleAddDevices}
                    isDisabled={devicesToAdd.some(
                      (device) => device === undefined
                    )}
                    isLoading={isLoading || isFetching}
                  >
                    Submit
                  </Button>
                </Tooltip>
              </Flex>
            </>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
