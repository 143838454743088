import {
  Button,
  Center,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  useToast,
  Text,
  Flex,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../contexts/AuthContext";
import { toastError } from "../../../../../Providers/ToastProvider";

import {
  ErrorAegisResponse,
  OptionalFeatureId,
} from "../../../../../types/api.types";
import { StateSetter } from "../../../../../types/types";
import { navigateToAdminConsent } from "../../../../../utils/utils";

export interface IOptionalFeatureModalConfirmProps {
  featureId: OptionalFeatureId;
  handleConfirm: () => Promise<void>;
  refetch: () => any;
  currentlyEnabled: boolean;
}

export interface IOptionalFeatureModalProps {
  tenantId: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  confirmProps: IOptionalFeatureModalConfirmProps | null;
  setConfirmProps: StateSetter<IOptionalFeatureModalConfirmProps | null>;
}

export default function ConfirmOptionalFeatureModal({
  tenantId,
  isOpen,
  onClose,
  confirmProps,
  setConfirmProps,
  onOpen,
}: Readonly<IOptionalFeatureModalProps>) {
  const [isLoading, setIsLoading] = useState(false);

  const toastProvider = useToast();

  const { isGov } = useContext(AuthContext);

  useEffect(() => {
    if (confirmProps) {
      onOpen();
    }
  }, [confirmProps, onOpen]);

  const featureFriendlyName = (featureId?: OptionalFeatureId) => {
    // get nice name for our modal
    switch (featureId) {
      case "vpn": {
        return "VPN";
      }
      case "duo:mfa": {
        return "Duo MFA";
      }
      case "training": {
        return "training status";
      }
      case "vpn:rdp": {
        return "Remote Desktop";
      }
      case "backup:settings":
        return "Backup Settings";
      case "vpn:settings":
        return "VPN Settings";
      case "usbs":
        return "Usb Control";
      default: {
        return "invalid feature id";
      }
    }
  };

  if (!confirmProps) {
    return <></>;
  }

  const handleConfirmation = async () => {
    setIsLoading(true);
    if (confirmProps.featureId) {
      try {
        // update status of feature to opposite of current value
        await confirmProps.handleConfirm();
        confirmProps.refetch();
      } catch (err) {
        console.error(err);
        toastError(
          toastProvider,
          (err as AxiosError<ErrorAegisResponse<any>>)?.response?.data.error ??
            err
        );
      }
    }
    setConfirmProps(null);
    setIsLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" margin={3}>
            <Heading size="md">{`${
              confirmProps.currentlyEnabled ? "Disable" : "Enable"
            } ${featureFriendlyName(confirmProps.featureId)}?`}</Heading>
            {confirmProps.featureId === "usbs" &&
              !confirmProps.currentlyEnabled && (
                <Flex flexDir="column" width="100%">
                  <Text fontSize="12pt" padding="10px" align="center">
                    This feature requires new permissions on the Aegis App and
                    Web App registrations.
                  </Text>
                  <Button
                    as={Flex}
                    flexDir="column"
                    mb="10px"
                    height="40px"
                    size="xs"
                    onClick={() => {
                      navigateToAdminConsent(tenantId, isGov, "aegisApp", true);
                      navigateToAdminConsent(tenantId, isGov, "webApp", true);
                    }}
                  >
                    <Text overflow="hidden" whiteSpace="normal">
                      Grant App Admin Consent
                    </Text>

                    <Text>(make sure browser allows pop-ups)</Text>
                  </Button>
                </Flex>
              )}
            <Center>
              <Stack direction="row" spacing={4}>
                <Button
                  colorScheme="blue"
                  onClick={() => handleConfirmation()}
                  isLoading={isLoading}
                >
                  Confirm
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    setConfirmProps(null);
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Center>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
