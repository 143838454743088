import {
  Button,
  Flex,
  Grid,
  Tag,
  Tooltip,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  DbDeviceRowFields,
  DeviceOS,
} from "../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../../../../../../types/types";
import { BsPlusCircle } from "react-icons/bs";
import { useContext, useState } from "react";
import { deleteDeviceForAegisUser } from "../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import useMicrosoftToken from "../../../../../../../../hooks/tokens.hooks";
import { TenantContext } from "../../../../../../TenantContext";
import { UserRowContext } from "../../../UserRowContext";
import { useAegisUsersDevices } from "../../../../../../../../hooks/users.hooks";
import { toastError } from "../../../../../../../../Providers/ToastProvider";
import { DeviceOnboardingStatus } from "../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.constants";

function DeleteTag({
  onClick,
  isLoading,
}: Readonly<{ onClick: () => void; isLoading: boolean }>) {
  return (
    <Tag
      as={Button}
      isLoading={isLoading}
      colorScheme="red"
      cursor="pointer"
      justifyContent="center"
      onClick={onClick}
      _hover={{ bg: "red.200" }}
    >
      Delete
    </Tag>
  );
}

interface IDeviceGridProps {
  thisUserDevices: DbDeviceRowFields[] | undefined;
  allOptionsSelected: boolean;
  setShowAddSection: StateSetter<boolean>;
  setDevicesToAdd: StateSetter<(DeviceOS | undefined)[]>;
}

export default function DeviceGrid({
  thisUserDevices,
  allOptionsSelected,
  setShowAddSection,
  setDevicesToAdd,
}: Readonly<IDeviceGridProps>) {
  const [isLoading, setIsLoading] = useState(false);
  const [deviceIdShowOptions, setDeviceIdShowOptions] = useState<string | null>(
    null
  );
  const { getInternalApiToken } = useMicrosoftToken();
  const { aegisUser } = useContext(UserRowContext);
  const { tenant } = useContext(TenantContext);
  const { refetch } = useAegisUsersDevices(tenant?.tenantId!);
  const toastProvider = useToast();

  const handleDeleteDevice = async () => {
    if (tenant && aegisUser && deviceIdShowOptions) {
      setIsLoading(true);
      try {
        const token = await getInternalApiToken();
        await deleteDeviceForAegisUser(
          token,
          tenant?.tenantId,
          aegisUser?.id,
          deviceIdShowOptions
        );
      } catch (error) {
        toastError(
          toastProvider,
          "Failed to delete device. Please contact success@atomuscyber.com if the issue persists.",
          3000
        );
      }
      await refetch();
      setIsLoading(false);
    }
  };

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      gap="8px"
      onMouseLeave={() => setDeviceIdShowOptions(null)}
    >
      {!thisUserDevices && <Tag justifyContent="center">None</Tag>}
      {thisUserDevices
        ?.toSorted((a, b) => a.status.length - b.status.length) // Sorting by 'Not onboarded'.length > 'onboarded'.length
        .map((device) => {
          return device.id === deviceIdShowOptions ? (
            <DeleteTag
              onClick={() => handleDeleteDevice()}
              isLoading={isLoading}
            />
          ) : (
            <Tooltip
              label="Device is onboarded"
              isDisabled={device.status !== DeviceOnboardingStatus.onboarded}
            >
              <Tag
                as={Button}
                onMouseOver={() => {
                  if (device.status === "Not onboarded") {
                    setDeviceIdShowOptions(device.id);
                  }
                }}
                _hover={{ bg: "green.200" }}
                cursor={"default"}
                justifyContent="center"
                key={device.id}
                colorScheme={
                  device.status === DeviceOnboardingStatus.onboarded
                    ? "green"
                    : "gray"
                }
              >
                {deviceIdShowOptions === device.id ? "Delete" : device.os}
              </Tag>
            </Tooltip>
          );
        })}
      <Tooltip
        label={
          allOptionsSelected
            ? "No more device types to add"
            : "Add another device"
        }
      >
        <Tag
          as={Button}
          variant="outline"
          cursor="pointer"
          colorScheme="blue"
          justifyContent="center"
          onClick={() => {
            setShowAddSection(true);
            setDevicesToAdd((current) => [...current, undefined]);
          }}
          isDisabled={allOptionsSelected}
        >
          <Flex>
            <BsPlusCircle />
          </Flex>
          <Text m="7px">Add More</Text>
        </Tag>
      </Tooltip>
    </Grid>
  );
}
