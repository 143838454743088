import {
  AtomusEmailCategory,
  ReadyToOnboardStatuses,
  UserReadyToOnboardStatuses,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";

/**
 * @description based on the array of email categories, get checks that need to be done
 * @param emailCategories array of selected email categories
 * @returns needed checks as booleans, dbDevicesCheck, aegisCheck, and appleCheck
 */
function getNeededChecks(emailCategories: AtomusEmailCategory[]) {
  const neededChecks = {
    dbDevicesCheck: false,
    aegisCheck: false,
    appleCheck: false,
  };
  for (const emailCategory of emailCategories) {
    if (emailCategory === "device-onboarding") {
      neededChecks.aegisCheck = true;
      neededChecks.dbDevicesCheck = true;
      neededChecks.aegisCheck = true;
    }
    if (
      emailCategory.startsWith("custom-windows") ||
      emailCategory.startsWith("custom-macos") ||
      emailCategory.startsWith("custom-ubuntu")
    ) {
      neededChecks.aegisCheck = true;
    }
    if (
      emailCategory.startsWith("custom-macos") ||
      emailCategory.startsWith("custom-ios") ||
      emailCategory.startsWith("custom-apple-credentials")
    ) {
      neededChecks.appleCheck = true;
    }
    if (
      neededChecks.aegisCheck &&
      neededChecks.appleCheck &&
      neededChecks.dbDevicesCheck
    ) {
      // minor optimization, stop iteration if all are true
      break;
    }
  }
  return neededChecks;
}

/**
 * @description add blocking reasons that would prevent any device email
 *  from being sent
 */
function getBaselineBlockers(
  reasons: string[],
  userStatus: UserReadyToOnboardStatuses
) {
  if (userStatus.azUserFound !== null) {
    reasons.push(userStatus.azUserFound);
  }
  if (userStatus.userHasCloudId !== null) {
    reasons.push(userStatus.userHasCloudId);
  }
  if (userStatus.userHasLicense !== null) {
    reasons.push(userStatus.userHasLicense);
  }
  if (userStatus.userHasMsftCreds !== null) {
    reasons.push(userStatus.userHasMsftCreds);
  }
  if (userStatus.userIsInCompanyGroup !== null) {
    reasons.push(userStatus.userIsInCompanyGroup);
  }
}

/**
 * @description add blocking reasons that would prevent any Aegis desktop app email
 *  from being sent
 */
function getAegisBlockers(
  reasons: string[],
  userStatus: UserReadyToOnboardStatuses,
  isDefaultEmail: boolean
) {
  if (userStatus.duoUserExists !== null) {
    reasons.push(userStatus.duoUserExists);
  }
  if (userStatus.userHasAppConfigReader !== null) {
    reasons.push(userStatus.userHasAppConfigReader);
  }
  if (userStatus.userHasCloudId !== null) {
    reasons.push(userStatus.userHasCloudId);
  }
  if (userStatus.userKeyVaultExists !== null) {
    reasons.push(userStatus.userKeyVaultExists);
  }
  // if checking db devices, check the user Apple creds status
  if (isDefaultEmail && userStatus.userAppleCheck !== null) {
    // is only error if user is missing Apple crerds AND has MacOS or iOS devices
    reasons.push(userStatus.userAppleCheck);
  }
}

/**
 * @description get the blocking reasons (if any exist) for a set of selected email categories
 * @param userStatus user level ready-to-onboard checks
 * @param companyStatus company level ready-to-onboard checks
 * @param emailCategories selected email categories
 * @returns null if no blocking reasons, otherwise a string explaining why it is blocking
 */
export function getUserBlockingReasons(
  userStatus: UserReadyToOnboardStatuses,
  companyStatus: Pick<
    ReadyToOnboardStatuses,
    "aegisAppConsentGranted" | "companyGroupFound"
  >,
  emailCategories: AtomusEmailCategory[]
): string | null {
  const { aegisCheck, appleCheck, dbDevicesCheck } =
    getNeededChecks(emailCategories);
  // initialize empty array of blocking reasons for email categories
  const reasons: string[] = [];
  // Checks that need to be done for any device email
  getBaselineBlockers(reasons, userStatus);
  if (companyStatus.companyGroupFound !== null) {
    reasons.push(companyStatus.companyGroupFound);
  }
  // Aegis only checks (not required checks for iOS/Android)
  if (aegisCheck) {
    getAegisBlockers(reasons, userStatus, dbDevicesCheck);
    if (companyStatus.aegisAppConsentGranted !== null) {
      reasons.push(companyStatus.aegisAppConsentGranted);
    }
  }
  // Apple only checks
  if (appleCheck) {
    if (userStatus.userHasAppleCreds !== null) {
      reasons.push(userStatus.userHasAppleCreds);
    }
  }
  // db Devices check for default device email
  if (dbDevicesCheck) {
    if (userStatus.userHasDevices !== null) {
      reasons.push(userStatus.userHasDevices);
    }
  }
  if (reasons.length !== 0) {
    return (
      reasons
        // filter out duplicate reasons for the same email
        .filter((reason, index) => reasons.indexOf(reason) === index)
        .join(", ")
    );
  } else {
    return null;
  }
}

/**
 * @description mapping of AtomusEmailCategory ids to their friendly names
 */
export const EMAIL_CATEGORY_MAP: { [key in AtomusEmailCategory]: string } = {
  "custom-android": "Android",
  "custom-ios": "iOS",
  "custom-macos-new-device": "MacOS (new device)",
  "custom-macos-partition": "MacOS (partition)",
  "custom-ubuntu": "Ubuntu",
  "custom-windows-app-only": "Windows (app only)",
  "custom-windows-new-device": "Windows (new device)",
  "custom-windows-partition": "Windows (partition)",
  "custom-windows-switch-user": "Windows (switch-user)",
  "custom-msft-credentials": "MSFT Credentials",
  "custom-apple-credentials": "Apple Credentials",
  "user-guide": "Intro User Guide",
  "cs-training": "Training",
  "employee-handbook": "Handbook",
  "device-onboarding": "Device onboarding (default)",
  "defender-app-recommendations": "Defender App Recommendations",
  "apple-password-reset": "Apple Password Reset",
  "msft-password-reset": "Microsoft Password Reset",
  "duo-offline-instructions": "Duo Offline Instructions",
};
