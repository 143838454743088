import {
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  PopoverBody,
  Flex,
  Input,
  Text,
  useToast,
  Center,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { BsMicrosoft } from "react-icons/bs";
import useMicrosoftToken from "../../../../../../../hooks/tokens.hooks";
import { useEmailTimeLines } from "../../../../../../../hooks/users.hooks";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../Providers/ToastProvider";
import { resetUserPassword } from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { UserRowContext } from "../../UserRowContext";

type IUserMicrosoftOptionsProps = Readonly<{
  tenantId: string;
}>;

export default function UserMicrosoftOptions({
  tenantId,
}: IUserMicrosoftOptionsProps) {
  const [altEmail, setAltEmail] = useState<string>();
  const [showResetOptions, setShowResetOptions] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const { getInternalApiToken } = useMicrosoftToken();
  const toast = useToast();
  const { aegisUser } = useContext(UserRowContext);
  const { refetch } = useEmailTimeLines(tenantId);

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      if (altEmail && aegisUser && tenantId) {
        const token = await getInternalApiToken();
        await resetUserPassword(token, tenantId, aegisUser.id, altEmail);
        setEmailSent(true);
        toastSuccess(
          toast,
          `Succesfully reset password for ${aegisUser.displayName}`
        );
      } else {
        throw new Error("missing required data to send password reset");
      }
    } catch (error) {
      toastError(toast, "Error resetting user's MSFT password");
    }
    refetch();
    setIsLoading(false);
  };

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          aria-label={"credentials"}
          variant="ghost"
          icon={<BsMicrosoft />}
          onClick={() => {
            setShowResetOptions(false);
            setEmailSent(false);
            setAltEmail(undefined);
          }}
        />
      </PopoverTrigger>
      <PopoverContent width="320px">
        <PopoverArrow />
        <PopoverCloseButton m="5px" />

        <PopoverBody>
          <Center>
            <Button
              mt="5px"
              mb="5px"
              size="sm"
              variant="outline"
              colorScheme="red"
              onClick={() => setShowResetOptions(true)}
              isDisabled={showResetOptions}
            >
              Reset Microsoft Password
            </Button>
          </Center>
          {showResetOptions && (
            <>
              <Text m="5px">
                Enter an alternative email address where the user can receive
                their credentials.
              </Text>
              <Flex mt="15px" mb="10px">
                <Input
                  placeholder="email@example.com"
                  onChange={(e) => setAltEmail(e.target.value)}
                  isDisabled={emailSent}
                ></Input>
                <Button
                  ml="10px"
                  colorScheme={emailSent ? "green" : "red"}
                  isDisabled={
                    (!!altEmail?.length && altEmail?.length <= 2) ||
                    !altEmail?.includes("@") ||
                    !altEmail?.includes(".") ||
                    emailSent
                  }
                  isLoading={isLoading}
                  onClick={handleResetPassword}
                >
                  <Text fontSize="10pt" whiteSpace="wrap">
                    {emailSent ? "Sent!" : "Reset and Send"}
                  </Text>
                </Button>
              </Flex>
            </>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
