import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  AccountSetupUser,
  DeviceOSValues,
  DeviceOS,
} from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { DEFAULT_USER } from "../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { useContext, useState } from "react";
import { toastError } from "../../../Providers/ToastProvider";
import { AccountSetupContext } from "./AccountSetupContext";

const deviceOSOptions = Object.values(DeviceOSValues);

interface IAccountSetupUsersModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AccountSetupUsersModal({
  isOpen,
  onClose,
}: IAccountSetupUsersModalProps) {
  const { accountInfo, users, setUsers } = useContext(AccountSetupContext);
  const [isLoading, setIsLoading] = useState(false);
  const toastProvider = useToast();

  if (!accountInfo) {
    return <></>;
  }

  const handleAddUser = () => {
    setUsers((prevUsers) => [...prevUsers, DEFAULT_USER]);
  };

  const handleRemoveUser = (index: Number) => {
    setUsers((prevUsers) => prevUsers.filter((_, i) => i !== index));
  };

  const inputIsValid = (submittedUsers: AccountSetupUser[]): boolean => {
    for (const user of submittedUsers) {
      for (const [key, value] of Object.entries(user)) {
        if (key === "phone") {
          continue;
        }
        if (key === "numberOfDevices") {
          continue;
        }
        if (key === "devices" && user.devices.length !== user.numberOfDevices) {
          return false;
        }
        if (!Boolean(value)) {
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsLoading(true);
    event.preventDefault();
    if (!inputIsValid(users)) {
      toastError(toastProvider, "At least one required input is missing.");
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    onClose();
  };

  const handleCancel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setUsers([]);
    onClose();
  };

  return (
    <Modal scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {users.map((newUser, index) => (
            <Box marginBottom="30px">
              <Divider
                borderWidth="2px"
                borderStyle="solid"
                marginBottom="10px"
              />
              <Text fontWeight="bold" fontSize="lg">
                Information for User No. {index + 1}
              </Text>
              <form>
                <FormControl>
                  <Box marginTop={4}>
                    <FormLabel>User Type</FormLabel>
                    <Select
                      value={newUser.userType}
                      onChange={(e) => {
                        const updatedUsers = [...users];
                        updatedUsers[index] = {
                          ...newUser,
                          userType: e.target.value,
                        };
                        setUsers(updatedUsers);
                      }}
                    >
                      <option value="" disabled>
                        Enter the user's role
                      </option>
                      <option value="user">User</option>
                      <option value="companyAdmin">Company Admin</option>
                    </Select>
                  </Box>
                </FormControl>

                <FormControl isRequired marginTop={4}>
                  <FormLabel>Name</FormLabel>
                  <Flex>
                    <Input
                      flex="1"
                      marginRight={2}
                      required
                      value={newUser.firstName}
                      placeholder="First"
                      onChange={(e) => {
                        const updatedUsers = [...users];
                        updatedUsers[index] = {
                          ...newUser,
                          firstName: e.target.value,
                        };
                        setUsers(updatedUsers);
                      }}
                    />
                    <Input
                      flex="1"
                      marginLeft={2}
                      required
                      value={newUser.lastName}
                      placeholder="Last"
                      onChange={(e) => {
                        const updatedUsers = [...users];
                        updatedUsers[index] = {
                          ...newUser,
                          lastName: e.target.value,
                        };
                        setUsers(updatedUsers);
                      }}
                    />
                  </Flex>
                </FormControl>

                <FormControl isRequired marginTop={4}>
                  <FormLabel>Username</FormLabel>
                  <Input
                    required
                    value={newUser.username}
                    placeholder={`Enter the username`}
                    onChange={(e) => {
                      const updatedUsers = [...users];
                      updatedUsers[index] = {
                        ...newUser,
                        username: e.target.value,
                      };
                      setUsers(updatedUsers);
                    }}
                  />
                </FormControl>

                <FormControl isRequired marginTop={4}>
                  <FormLabel>Temporary Password</FormLabel>
                  <Input
                    required
                    value={newUser.msftTempPass}
                    placeholder={`Enter the user's temporary password`}
                    onChange={(e) => {
                      const updatedUsers = [...users];
                      updatedUsers[index] = {
                        ...newUser,
                        msftTempPass: e.target.value,
                      };
                      setUsers(updatedUsers);
                    }}
                  />
                </FormControl>

                <FormControl isRequired marginTop={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    required
                    value={newUser.email}
                    placeholder={`Enter the user's contact email`}
                    onChange={(e) => {
                      const updatedUsers = [...users];
                      updatedUsers[index] = {
                        ...newUser,
                        email: e.target.value,
                      };
                      setUsers(updatedUsers);
                    }}
                  />
                </FormControl>

                <FormControl marginTop={4}>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    value={newUser.phone}
                    placeholder={`Enter the user's phone number (optional)`}
                    onChange={(e) => {
                      const updatedUsers = [...users];
                      updatedUsers[index] = {
                        ...newUser,
                        phone: e.target.value,
                      };
                      setUsers(updatedUsers);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <Box marginTop={4}>
                    <FormLabel>Number of Devices</FormLabel>
                    <Select
                      id={"numberOfDevices"}
                      key={"numberOfDevices"}
                      value={newUser.numberOfDevices}
                      placeholder={`Select the number of devices.`}
                      onChange={(e) => {
                        const selectedValue = parseInt(e.target.value);
                        const updatedUsers = [...users];
                        updatedUsers[index] = {
                          ...newUser,
                          numberOfDevices: selectedValue,
                        };
                        setUsers(updatedUsers);
                      }}
                    >
                      {[...Array(10)].map((_, index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </FormControl>

                <FormControl>
                  <Box marginTop={4}>
                    {newUser.numberOfDevices > 0 && (
                      <FormLabel>Devices</FormLabel>
                    )}
                    {[...Array(newUser.numberOfDevices)].map(
                      (_, deviceIndex) => (
                        <Flex>
                          <Select
                            mb={2}
                            key={deviceIndex}
                            value={newUser.devices[deviceIndex] || ""}
                            onChange={(e) => {
                              const updatedDevices = [...newUser.devices];
                              updatedDevices[deviceIndex] = e.target
                                .value as DeviceOS;
                              const updatedUsers = [...users];
                              updatedUsers[index] = {
                                ...newUser,
                                devices: updatedDevices,
                              };
                              setUsers(updatedUsers);
                            }}
                          >
                            <option value="" disabled>{`OS for device #${
                              deviceIndex + 1
                            }`}</option>
                            {deviceOSOptions.map((device) => (
                              <option key={device} value={device}>
                                {device}
                              </option>
                            ))}
                          </Select>
                        </Flex>
                      )
                    )}
                  </Box>
                </FormControl>
                <Flex marginY={4} align="flex-end" width="100%">
                  {index === users.length - 1 && (
                    <Button onClick={handleAddUser}>Add Another User</Button>
                  )}
                  <Spacer />

                  {index > 0 && (
                    <Button
                      onClick={() => handleRemoveUser(index)}
                      variant="outline"
                    >
                      Remove
                    </Button>
                  )}
                </Flex>
              </form>
            </Box>
          ))}
          <form>
            <Flex
              marginY={4}
              align="flex-end"
              width="100%"
              direction="row-reverse"
            >
              <Button
                type="submit"
                onClick={handleSubmit}
                colorScheme="blue"
                marginLeft={2}
                isLoading={isLoading}
              >
                Submit
              </Button>
              <Button
                onClick={handleCancel}
                variant="outline"
                isLoading={isLoading}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
