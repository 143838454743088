import { createContext, ReactNode, useMemo, useState } from "react";
import { Device } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { StateSetter } from "../../../types/types";
import { IntuneMobileDeviceTypes } from "../../../services/atomus-internal-apis/atomus-internal-apis.constants";

export interface IDevicesSectionContext {
  atomusDevices: Device[];
  nonAtomusDevices: Device[];
  disabledDevices: Device[];
  mobileDevices: Device[];
  devices: Device[] | null;
  setDevices: StateSetter<Device[] | null>;
}

const defaultError = () => {
  throw new Error("you must wrap your component in an DevicesTableContext");
};

const defaultValue: IDevicesSectionContext = {
  atomusDevices: [],
  nonAtomusDevices: [],
  disabledDevices: [],
  mobileDevices: [],
  devices: null,
  setDevices: defaultError,
};

export const DevicesSectionContext = createContext(defaultValue);

export const DevicesSectionContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [devices, setDevices] = useState<Device[] | null>(null);

  const atomusDevices = useMemo(() => {
    return (devices ?? []).filter(
      (device) =>
        device.accountEnabled &&
        (device.operatingSystem === "Windows" ||
          device.operatingSystem === "MacMDM" ||
          device.operatingSystem === "Linux") && // Linux only exists here because currently only fetched from db (must be Atomus device)
        device.registeredUsers?.find((user) => user.existsInDB && user.active)
    );
  }, [devices]);

  const nonAtomusDevices = useMemo(() => {
    return (devices ?? []).filter(
      (device) =>
        device.accountEnabled &&
        (device.operatingSystem === "Windows" ||
          device.operatingSystem === "MacMDM") &&
        !device.registeredUsers?.find((user) => user.existsInDB && user.active)
    );
  }, [devices]);

  const disabledDevices = useMemo(() => {
    return (devices ?? []).filter(
      (device) =>
        !device.accountEnabled &&
        (device.operatingSystem === "Windows" ||
          device.operatingSystem === "MacMDM")
    );
  }, [devices]);

  const mobileDevices = useMemo(() => {
    return (devices ?? []).filter((device) =>
      IntuneMobileDeviceTypes.includes(device.operatingSystem.toLowerCase())
    );
  }, [devices]);

  const contextValue = useMemo(() => {
    return {
      atomusDevices,
      nonAtomusDevices,
      disabledDevices,
      mobileDevices,
      devices,
      setDevices,
    };
  }, [
    atomusDevices,
    devices,
    disabledDevices,
    mobileDevices,
    nonAtomusDevices,
  ]);

  return (
    <DevicesSectionContext.Provider value={contextValue}>
      {children}
    </DevicesSectionContext.Provider>
  );
};
