import {
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Center,
  Tag,
  Text,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { useContext } from "react";
import { UserTableContext } from "../../../UserTableContext";
import RecipientsTableRow from "./RecipientsTableRow";
import { useCompanyDeviceOnboardingChecks } from "../../../../../../../hooks/users.hooks";
import { BsArrowClockwise } from "react-icons/bs";

interface IRecipientsTableProps {
  tenantId: string;
}

export default function RecipientsTable({
  tenantId,
}: Readonly<IRecipientsTableProps>) {
  const { selectedUserIds, usersArr } = useContext(UserTableContext);

  const { isError, refetch } = useCompanyDeviceOnboardingChecks(tenantId);

  if (isError) {
    return (
      <Center>
        <Tag colorScheme="red">
          An unknown error has occurred. Please contact success@atomuscyber.com
          if this issue persists.
        </Tag>
      </Center>
    );
  }

  return (
    <Flex flexDir="column" padding="20px" maxHeight="210px" overflowY="auto">
      <Table>
        <Thead>
          <Tr>
            <Th color="gray.800">
              <Text mb="-10px">
                {Object.keys(selectedUserIds).length > 1
                  ? "Recipients"
                  : "Recipient"}
              </Text>
            </Th>
            <Th />
            <Th>
              <Tooltip label="Refresh">
                <IconButton
                  ml="7px"
                  mb="-5px"
                  boxSize="25px"
                  icon={<BsArrowClockwise size="20px" />}
                  color="blue.500"
                  variant="ghost"
                  aria-label={"refresh-status"}
                  onClick={() => refetch()}
                />
              </Tooltip>
            </Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {usersArr
            .filter((user) => Object.keys(selectedUserIds).includes(user.id))
            .map((user) => (
              <RecipientsTableRow
                tenantId={tenantId}
                user={user}
                key={user.id}
              />
            ))}
        </Tbody>
      </Table>
    </Flex>
  );
}
