import axios from "axios";
import { Invoice } from "@azure/arm-billing";
import { MAC_CONFIG_PROFILE_BODY } from "./atomus-internal-apis.constants";
import {
  AegisUser,
  AegisAppConfig,
  AppInfoResponse,
  Device,
  DnsResult,
  SupportedPlatform,
  RegKeyConfiguration,
  UpdateStagingPercentageResponse,
  RegisteredUsersData,
  VpnSslResponse,
  ArcExpirationReponseBody,
  SubscriptionIdResponseBody,
  TenantListResponse,
  IntuneUploadResponse,
  ConfigValidationError,
  AccountSetupUser,
  AccountSetupResult,
  ApiActivityRow,
  HealthcheckRow,
  OnboardingsRow,
  AzureUserInfo,
  DashboardRole,
  DefenderEnrollmentStatuses,
  CompanyBrandingImages,
  AccountInfoWithOptionalLocation,
  AccountSetupStepStatuses,
  AtomusGroupsStatuses,
  ExternalAzureUser,
  ExternalAzureUserWithId,
  ExternalUserInvitationReponse,
  GroupRoles,
  SecurityRecommendation,
  CheckrCandidate,
  UserCheckrStatusRow,
  UsbAccessRequest,
  CompanyMsftInfo,
  AtomusEmailCategory,
  UserEmailsResponseBody,
  UserEmailsRequestBody,
  ReadyToOnboardStatuses,
  EmailTimelines,
  DefenderMachineRecipient,
  DeviceOS,
  UserAppleCreds,
  DuoSmsActivationRequestBody,
  DbDeviceRowFields,
} from "./atomus-internal-apis.types";
import {
  handleApiError,
  isErrorAegisResponse,
  isSuccessAegisResponse,
  validateAegisResponse,
} from "../../utils/utils";
import {
  OptionalFeatureAvailability,
  OptionalFeatureId,
  SuccessAegisResponse,
} from "../../types/api.types";
import { DuoUser } from "../../types/duo.types";
import {
  CompanyBackupPreferences,
  CompanyVpnPreferences,
} from "../../types/backup-preferences.types";

const BASE_URL =
  process.env.REACT_APP_INTERNAL_BASE_URL ?? "http://localhost:5005";

export const internalApiInstance = axios.create({ baseURL: BASE_URL });

export async function listTenants(token: string): Promise<TenantListResponse> {
  const path = "/tenants";
  return sendGet(path, token);
}

export async function listDevices(
  token: string,
  tenantId: string
): Promise<Device[]> {
  const path = `/tenants/${tenantId}/devices`;
  return sendGet(path, token);
}

export async function listDbDevices(
  token: string,
  tenantId: string
): Promise<Device[]> {
  const path = `/tenants/${tenantId}/devices/db`;
  return sendGet(path, token);
}

export async function getDeviceUsers(
  token: string,
  tenantId: string,
  deviceId: string
): Promise<RegisteredUsersData[]> {
  const path = `/tenants/${tenantId}/devices/${deviceId}/users`;
  return sendGet(path, token);
}

export async function getTenantAppInfo(
  token: string,
  tenantId: string,
  platform: SupportedPlatform
): Promise<AppInfoResponse> {
  return sendGet(`/tenants/${tenantId}/app-info?platform=${platform}`, token);
}

export async function getTenantDnsStatus(
  token: string,
  tenantId: string
): Promise<DnsResult> {
  return sendGet(`/tenants/${tenantId}/dns`, token);
}

export async function getVpnSslStatus(
  token: string,
  tenantId: string
): Promise<VpnSslResponse> {
  return await sendGet<VpnSslResponse>(
    `/tenants/${tenantId}/state/vpn-ssl`,
    token
  );
}

export async function updateOptionalFeature(
  token: string,
  tenantId: string,
  featureId: OptionalFeatureId,
  setTo: boolean
): Promise<void> {
  const updateConfigPath = `/tenants/${tenantId}/app-config`;
  const currentConfig = await getAppConfig(token, tenantId);
  // set to current value or empty obj if it does not exist
  const features = currentConfig?.OptionalFeatureSubscriptions || {};
  // change status depending on 'enabled'
  features[featureId] = setTo ? {} : undefined;
  const updateConfigRes = await internalApiInstance.patch<
    SuccessAegisResponse<{}>
  >(
    updateConfigPath,
    { OptionalFeatureSubscriptions: features },
    { headers: { authorization: token } }
  );
  if (isErrorAegisResponse(updateConfigRes)) {
    throw new Error("Received invalid response on update optional feature");
  }
}

export async function pushLatestApp(
  token: string,
  tenantId: string,
  platform: string,
  version: string
): Promise<void> {
  try {
    // push latest version of app
    const uploadAppPath = `/tenants/${tenantId}/app-info/upload?platform=${platform}`;
    const uploadAppRes = await internalApiInstance.post<
      SuccessAegisResponse<{}>
    >(
      uploadAppPath,
      {},
      {
        headers: { authorization: token },
      }
    );
    // make patch call to update Aegis version to current app version
    const updateConfigPath = `/tenants/${tenantId}/app-config`;
    const updateConfigRes = await internalApiInstance.patch<
      SuccessAegisResponse<{}>
    >(
      updateConfigPath,
      { AegisVersion: version },
      { headers: { authorization: token } }
    );
    if (isErrorAegisResponse(updateConfigRes)) {
      throw new Error("Received invalid response on update app config version");
    }
    if (isSuccessAegisResponse(uploadAppRes.data)) {
      return;
    }
    throw new Error("Received invalid response type from automate");
  } catch (error) {
    handleApiError(error);
  }
}

export async function updateStagingPercentage(
  token: string,
  tenantId: string,
  stagingPercentage: number,
  platform: SupportedPlatform
): Promise<UpdateStagingPercentageResponse> {
  try {
    const path = `/tenants/${tenantId}/app-info/update?platform=${platform}`;
    return await sendPost(path, token, { stagingPercentage });
  } catch (error) {
    handleApiError(error);
  }
}

export async function getRegistryKeys(
  token: string,
  tenantId: string
): Promise<RegKeyConfiguration> {
  try {
    const path = `/tenants/${tenantId}/keys?platform=win32`;
    if (tenantId === "global") {
      const res = await sendGet<RegKeyConfiguration>(path, token);
      return res;
    }
    return sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function updateRegistryKeys(
  token: string,
  tenantId: string,
  keyConfig: RegKeyConfiguration
): Promise<void> {
  try {
    const path = `/tenants/${tenantId}/keys?platform=win32`;
    await sendPost(path, token, { keys: keyConfig });
  } catch (error) {
    handleApiError(error);
  }
}

export async function getDeviceHealthcheck(
  token: string,
  tenantId: string
): Promise<HealthcheckRow[] | null> {
  try {
    const path = `/tenants/${tenantId}/devices/healthcheck`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getAegisUsers(
  token: string,
  tenantId: string
): Promise<AegisUser[]> {
  try {
    const path = `/tenants/${tenantId}/users`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getDevicesForAegisUsers(
  token: string,
  tenantId: string
): Promise<Record<string, DbDeviceRowFields[]>> {
  try {
    const path = `/tenants/${tenantId}/users/db-devices`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function addDevicesForAegisUsers(
  token: string,
  tenantId: string,
  userId: string,
  deviceOs: DeviceOS[]
) {
  const path = `/tenants/${tenantId}/users/${userId}/db-devices`;
  return await sendPost(path, token, { deviceOs });
}

export async function deleteDeviceForAegisUser(
  token: string,
  tenantId: string,
  userId: string,
  deviceId: string
) {
  const path = `/tenants/${tenantId}/users/${userId}/db-devices/${deviceId}`;
  return await sendDelete(path, token);
}

export async function createAegisUser(
  token: string,
  tenantId: string,
  user: AccountSetupUser
): Promise<AccountSetupUser | null> {
  try {
    const path = `/tenants/${tenantId}/users`;
    return await sendPost(path, token, user);
  } catch (error) {
    handleApiError(error);
  }
}

export async function deactivateUser(
  token: string,
  tenantId: string,
  userId: string
): Promise<AccountSetupUser | null> {
  try {
    const path = `/tenants/${tenantId}/users/${userId}/deactivate`;
    return await sendPost(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function reactivateUser(
  token: string,
  tenantId: string,
  userId: string
): Promise<AccountSetupUser | null> {
  try {
    const path = `/tenants/${tenantId}/users/${userId}/reactivate`;
    return await sendPost(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function resetUserPassword(
  token: string,
  tenantId: string,
  userId: string,
  contactEmail: string
) {
  try {
    const path = `/tenants/${tenantId}/users/${userId}/reset-msft-password`;
    return await sendPost(path, token, { contactEmail });
  } catch (error) {
    handleApiError(error);
  }
}

export async function updateAppleCredentials(
  token: string,
  tenantId: string,
  userId: string,
  appleId: string | null,
  appleTempPass: string | null
): Promise<UserAppleCreds> {
  try {
    const path = `/tenants/${tenantId}/users/${userId}/apple-credentials`;
    return await sendPatch(path, token, { appleId, appleTempPass });
  } catch (error) {
    handleApiError(error);
  }
}

export async function getDashboardRole(token: string): Promise<DashboardRole> {
  try {
    const path = `/roles/dashboard`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getAzureUserInfo(
  token: string,
  tenantId: string,
  userId: string
): Promise<AzureUserInfo> {
  try {
    const path = `/tenants/${tenantId}/users/msft/${userId}`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getGroupStatuses(
  token: string,
  tenantId: string
): Promise<AtomusGroupsStatuses> {
  const path = `/tenants/${tenantId}/groups`;
  return sendGet(path, token);
}

export async function getGroupRoles(
  token: string,
  tenantId: string,
  groupId: string
): Promise<GroupRoles> {
  const path = `/tenants/${tenantId}/groups/${groupId}/roles`;
  return sendGet(path, token);
}

export async function getGroupUsers(
  token: string,
  tenantId: string,
  groupId: string
): Promise<ExternalAzureUserWithId[]> {
  const path = `/tenants/${tenantId}/groups/${groupId}/users`;
  return sendGet(path, token);
}

export async function addGroupUser(
  token: string,
  tenantId: string,
  groupId: string,
  userId: string
): Promise<null> {
  const path = `/tenants/${tenantId}/groups/${groupId}/users/${userId}`;
  return sendPost(path, token);
}

export async function removeGroupUser(
  token: string,
  tenantId: string,
  groupId: string,
  userId: string
): Promise<null> {
  const path = `/tenants/${tenantId}/groups/${groupId}/users/${userId}`;
  return sendDelete(path, token);
}

export async function getExternalAtomusUsers(
  token: string,
  tenantId: string
): Promise<ExternalAzureUserWithId[]> {
  const path = `/tenants/${tenantId}/users/msft/externalAtomus?unassigned=true`;
  return sendGet(path, token);
}

export async function createExternalUsers(
  token: string,
  tenantId: string,
  users: ExternalAzureUser[]
): Promise<ExternalUserInvitationReponse[]> {
  const path = `/tenants/${tenantId}/users/msft/externalAtomus`;
  return sendPost(path, token, { users });
}

export async function uploadLoginBackground(
  token: string,
  tenantId: string,
  file: File
): Promise<null> {
  try {
    const formData = new FormData();
    formData.append("backgroundImageFile", file);
    const path = `/tenants/${tenantId}/branding/loginbackground`;
    return await sendPost(path, token, formData);
  } catch (error) {
    handleApiError(error);
  }
}

export async function uploadLogo(
  token: string,
  tenantId: string,
  file: File
): Promise<null> {
  try {
    const formData = new FormData();
    formData.append("logoFile", file);
    const path = `/tenants/${tenantId}/branding/logo`;
    return await sendPost(path, token, formData);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getBrandingImages(
  token: string,
  tenantId: string
): Promise<CompanyBrandingImages> {
  try {
    const path = `/tenants/${tenantId}/branding/images`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function sendDefenderAppRecsEmail(
  token: string,
  tenantId: string,
  recipient: DefenderMachineRecipient
): Promise<void> {
  try {
    const path = `/tenants/${tenantId}/emails/defender-app-recommendations`;
    await sendPost(path, token, recipient);
  } catch (error) {
    handleApiError(error);
  }
}

export async function sendUserEmails(
  token: string,
  tenantId: string,
  emailCategories: AtomusEmailCategory[],
  userIdEmailMap: Record<string, string>
): Promise<UserEmailsResponseBody> {
  try {
    const path = `/tenants/${tenantId}/emails/send`;
    return await sendPost<UserEmailsResponseBody>(path, token, {
      emailCategories,
      userIdEmailMap,
    } as UserEmailsRequestBody);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getEmailTimelines(
  token: string,
  tenantId: string
): Promise<EmailTimelines> {
  try {
    const path = `/tenants/${tenantId}/emails/timelines`;
    return await sendGet<EmailTimelines>(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function pushMacConfigProfiles(
  token: string,
  tenantId: string
): Promise<void> {
  try {
    const path = `/tenants/${tenantId}/intune`;
    await sendPost(path, token, MAC_CONFIG_PROFILE_BODY);
  } catch (error) {
    handleApiError(error);
  }
}

export async function triggerIntuneSync(
  token: string,
  tenantId: string
): Promise<void> {
  try {
    const path = `/tenants/${tenantId}/intune/sync`;
    await sendPost(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getAppConfig(
  token: string,
  tenantId: string
): Promise<AegisAppConfig> {
  try {
    const path = `/tenants/${tenantId}/app-config`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function uploadAppConfig(
  token: string,
  tenantId: string,
  configData: Record<string, any>
): Promise<void> {
  try {
    const path = `/tenants/${tenantId}/app-config`;
    await sendPost(path, token, configData);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getInvoices(
  token: string,
  tenantId: string
): Promise<Invoice[]> {
  const path = `/tenants/${tenantId}/invoices`;
  return sendGet(path, token);
}

export async function getArcSecret(
  token: string,
  tenantId: string
): Promise<ArcExpirationReponseBody> {
  const path = `/tenants/${tenantId}/state/arc-expiration`;
  return sendGet(path, token);
}

export async function getDefenderStatuses(
  token: string,
  tenantId: string
): Promise<DefenderEnrollmentStatuses> {
  const path = `/tenants/${tenantId}/state/defender-enrollment`;
  return sendGet(path, token);
}

export async function getSubscriptionId(
  token: string,
  tenantId: string
): Promise<SubscriptionIdResponseBody> {
  const path = `/tenants/${tenantId}/subscription-id`;
  return sendGet(path, token);
}

export async function getIntuneAppStatus(
  token: string,
  tenantId: string,
  platform: SupportedPlatform
): Promise<boolean> {
  const queryParams = new URLSearchParams({ platform });
  const path = `/tenants/${tenantId}/app-info/intune?${queryParams.toString()}`;
  return sendGet(path, token);
}

export async function pushIntuneApp(
  token: string,
  tenantId: string,
  platform: SupportedPlatform
): Promise<IntuneUploadResponse> {
  const queryParams = new URLSearchParams({ platform });
  const path = `/tenants/${tenantId}/app-info/intune?${queryParams.toString()}`;
  return sendPost(path, token, {});
}

export async function getAccountSetupAccountInfo(
  token: string,
  tenantId: string
): Promise<AccountInfoWithOptionalLocation> {
  const queryParams = new URLSearchParams({ tenantId });
  const path = `/account-setup/account-info?${queryParams.toString()}`;
  return sendGet(path, token);
}

export async function getAccountSetupStepStatuses(
  token: string,
  tenantId: string
): Promise<AccountSetupStepStatuses> {
  const queryParams = new URLSearchParams({ tenantId });
  const path = `/account-setup/statuses?${queryParams.toString()}`;
  return sendGet(path, token);
}

export async function runAccountSetup(
  token: string,
  tenantId: string,
  accountInfo: {
    location: string;
    users?: AccountSetupUser[];
    defenderOnboardingPayload?: string;
  },
  steps?: string[]
): Promise<AccountSetupResult> {
  if (!accountInfo.users) {
    accountInfo.users = [];
  }
  const body = { ...accountInfo, steps };
  const queryParams = new URLSearchParams({ tenantId });
  const path = steps
    ? `/account-setup/steps?${queryParams.toString()}`
    : `/account-setup?${queryParams.toString()}`;
  return sendPost(path, token, body);
}

export async function validateConfig(
  token: string,
  tenantId: string
): Promise<ConfigValidationError | null> {
  const path = `/tenants/${tenantId}/healthcheck/aegis-config`;
  return sendGet(path, token);
}

export async function getTenantActivityLogCount(
  token: string,
  tenantId: string
): Promise<number> {
  try {
    const path = `/tenants/${tenantId}/activity-logs/count`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getActivityLogs(
  token: string,
  tenantId: string,
  pageNum: number,
  numResults: number
): Promise<ApiActivityRow[]> {
  const queryParams = new URLSearchParams({
    pageNum: pageNum.toString(),
    numResults: numResults.toString(),
  });
  try {
    const path = `/tenants/${tenantId}/activity-logs?${queryParams.toString()}`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}
export async function getTenantOnboarding(
  token: string,
  tenantId: string
): Promise<OnboardingsRow | null> {
  try {
    const path = `/tenants/${tenantId}/onboarding`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getDuoUser(
  token: string,
  tenantId: string,
  username: string
): Promise<DuoUser> {
  try {
    const searchParams = new URLSearchParams({ username });
    const path = `/tenants/${tenantId}/duo/users?${searchParams.toString()}`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function sendDuoActivationTextAndEmail(
  token: string,
  tenantId: string,
  body: DuoSmsActivationRequestBody
): Promise<null> {
  try {
    const path = `/tenants/${tenantId}/duo/send-activation`;
    return await sendPost(path, token, body);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getCheckrConnectionStatus(
  token: string,
  tenantId: string
): Promise<boolean> {
  try {
    const path = `/tenants/${tenantId}/integrations/checkr/connection`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getCheckrCandidates(
  token: string,
  tenantId: string
): Promise<CheckrCandidate[]> {
  try {
    const path = `/tenants/${tenantId}/integrations/checkr/candidates`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function mapCheckrCandidate(
  token: string,
  tenantId: string,
  userId: string,
  checkrId: string
): Promise<CheckrCandidate[]> {
  try {
    const path = `/tenants/${tenantId}/integrations/checkr/map`;
    return await sendPost(path, token, { userId, checkrId });
  } catch (error) {
    handleApiError(error);
  }
}

export async function unmapCheckrCandidate(
  token: string,
  tenantId: string,
  checkrId: string
): Promise<CheckrCandidate[]> {
  try {
    const path = `/tenants/${tenantId}/integrations/checkr/map?checkrId=${checkrId}`;
    return await sendDelete(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getCheckrStatus(
  token: string,
  tenantId: string,
  checkrId: string | null
): Promise<UserCheckrStatusRow> {
  try {
    const path = `/tenants/${tenantId}/integrations/checkr/status?checkrId=${checkrId}`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getOptionalFeatureAvailability(
  token: string,
  tenantId: string,
  featureId: OptionalFeatureId,
  deviceName?: string,
  username?: string
): Promise<OptionalFeatureAvailability> {
  try {
    const searchParams = new URLSearchParams({ featureId });
    if (deviceName) {
      searchParams.set("deviceName", deviceName);
    }
    if (username) {
      searchParams.set("username", username);
    }
    const path = `/tenants/${tenantId}/optional-features/availability?${searchParams.toString()}`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description get whether or not a user is ready to be onboarded to aegis using
 * the device data in the db
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @returns object containing info on if the user is ready to onboard
 */
export async function getUsersReadyToOnboard(
  token: string,
  tenantId: string
): Promise<ReadyToOnboardStatuses> {
  try {
    const path = `/tenants/${tenantId}/users/ready-to-onboard`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description disable a device in Azure
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @param deviceId id of the device to disable
 */
export async function disableDevice(
  token: string,
  tenantId: string,
  deviceId: string
): Promise<null> {
  try {
    const path = `/tenants/${tenantId}/devices/${deviceId}/disable`;
    return await sendPost(path, token, {});
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description enable a device in Azure
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @param deviceId id of the device to disable
 */
export async function enableDevice(
  token: string,
  tenantId: string,
  deviceId: string
): Promise<null> {
  try {
    const path = `/tenants/${tenantId}/devices/${deviceId}/enable`;
    return await sendPost(path, token, {});
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description update the primary user of a device on intune
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @param deviceId id of the device
 * @param msftUserId MSFT id of the user
 */
export async function updateDevicePrimaryUser(
  token: string,
  tenantId: string,
  deviceId: string,
  msftUserId: string
): Promise<null> {
  try {
    const path = `/tenants/${tenantId}/devices/${deviceId}/primary-user`;
    return await sendPost(path, token, { msftUserId });
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description delete an Azure device
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @param deviceId id of the device to get
 */
export async function deleteDevice(
  token: string,
  tenantId: string,
  deviceId: string
): Promise<null> {
  try {
    const path = `/tenants/${tenantId}/devices/${deviceId}`;
    return await sendDelete(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description get a list of app security recommendations for all devices
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @returns a map of device names to their security recommendations
 */
export async function getDeviceAppRecommendations(
  token: string,
  tenantId: string
): Promise<
  Record<
    string,
    Pick<
      SecurityRecommendation,
      "id" | "recommendationName" | "relatedComponent" | "remediationType"
    >[]
  >
> {
  try {
    const path = `/tenants/${tenantId}/devices/app-recommendations`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description get a company's aegis backup preferences
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @returns the company-wide backup schedule preferences from the company key vault
 * will include all user-specific preferences as well
 */
export async function getCompanyBackupPreferences(
  token: string,
  tenantId: string
): Promise<CompanyBackupPreferences> {
  try {
    const path = `/tenants/${tenantId}/preferences/app/backup`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description updat a company's aegis backup preferences
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @param prefs the updated preferences
 * @returns the updated preferences
 */
export async function setCompanyBackupPreferences(
  token: string,
  tenantId: string,
  prefs: CompanyBackupPreferences
): Promise<CompanyBackupPreferences> {
  try {
    const path = `/tenants/${tenantId}/preferences/app/backup`;
    return await sendPost(path, token, prefs);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description get a company's aegis vpn preferences
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @returns the company-wide vpn preferences from the company key vault
 * will include all user-specific preferences as well
 */
export async function getCompanyVpnPreferences(
  token: string,
  tenantId: string
): Promise<CompanyVpnPreferences> {
  try {
    const path = `/tenants/${tenantId}/preferences/app/vpn`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description updat a company's aegis vpn preferences
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @param prefs the updated preferences
 * @returns the updated preferences
 */
export async function setCompanyVpnPreferences(
  token: string,
  tenantId: string,
  prefs: CompanyVpnPreferences
): Promise<CompanyVpnPreferences> {
  try {
    const path = `/tenants/${tenantId}/preferences/app/vpn`;
    return await sendPost(path, token, prefs);
  } catch (error) {
    handleApiError(error);
  }
}

/**
 * @description get whether or not a feature is disabled
 * @param token token to authenticate the request
 * @param tenantId id of the tenant
 * @param featureId feature id
 * @returns the disabled status of the feature
 */
export async function getFeatureIsDisabled(
  token: string,
  tenantId: string,
  featureId: string
): Promise<{ isDisabled: boolean }> {
  try {
    const path = `/tenants/${tenantId}/disabled-features?${new URLSearchParams({
      featureId,
    })}`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function getUsbRequests(
  token: string,
  tenantId: string
): Promise<UsbAccessRequest[]> {
  try {
    const path = `/tenants/${tenantId}/devices/usbs`;
    return await sendGet(path, token);
  } catch (error) {
    handleApiError(error);
  }
}

export async function updateUsbRequest(
  token: string,
  tenantId: string,
  updatedRequest: Pick<
    UsbAccessRequest,
    "platform" | "serialNumbers" | "approvalStatus" | "approvedBy"
  >
): Promise<UsbAccessRequest> {
  try {
    const path = `/tenants/${tenantId}/devices/usbs`;
    return await sendPatch(path, token, updatedRequest);
  } catch (error) {
    handleApiError(error);
  }
}

export async function updateCompanyMsftInfo(
  token: string,
  companyId: string,
  body: Pick<CompanyMsftInfo, "tenantId" | "subscriptionId" | "cloudInstance">
): Promise<CompanyMsftInfo> {
  try {
    const path = `/companies/${companyId}/msft-info`;
    return await sendPost(path, token, body);
  } catch (error) {
    handleApiError(error);
  }
}

/* ======================================== generic/helper functions  ======================================== */

export async function sendGet<T>(path: string, token: string): Promise<T> {
  try {
    const res = await internalApiInstance.get<SuccessAegisResponse<T>>(path, {
      headers: { authorization: token },
    });
    return validateAegisResponse(res);
  } catch (error) {
    handleApiError(error);
  }
}

export async function sendPost<T>(
  path: string,
  token: string,
  data?: any
): Promise<T> {
  try {
    const res = await internalApiInstance.post<SuccessAegisResponse<T>>(
      path,
      data || {},
      {
        headers: { authorization: token },
      }
    );
    return validateAegisResponse(res);
  } catch (error) {
    handleApiError(error);
  }
}

export async function sendPatch<T>(
  path: string,
  token: string,
  data?: any
): Promise<T> {
  try {
    const res = await internalApiInstance.patch<SuccessAegisResponse<T>>(
      path,
      data || {},
      {
        headers: { authorization: token },
      }
    );
    return validateAegisResponse(res);
  } catch (error) {
    handleApiError(error);
  }
}

export async function sendDelete<T>(path: string, token: string): Promise<T> {
  try {
    const res = await internalApiInstance.delete<SuccessAegisResponse<T>>(
      path,
      {
        headers: { authorization: token },
      }
    );
    return validateAegisResponse(res);
  } catch (error) {
    handleApiError(error);
  }
}
