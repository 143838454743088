import {
  Td,
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  Tbody,
  Tr,
  CircularProgress,
  Tag,
  Text,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import {
  BsLaptop,
  BsLightbulb,
  BsMailbox,
  BsPen,
  BsPerson,
} from "react-icons/bs";
import { useEmailTimeLines } from "../../../../../hooks/users.hooks";
import { useEffect, useMemo, useState } from "react";
import { EmailTimelineEntry } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";

const NAME_CHARS_SHOWN = 66;

export default function UserEmailTimeline({
  userId,
  tenantId,
}: Readonly<{ userId: string; tenantId: string }>) {
  const { data, isError, isFetching } = useEmailTimeLines(tenantId);
  const [sortedTimeline, setSortedTimeline] = useState<EmailTimelineEntry[]>();

  useEffect(() => {
    if (data) {
      const thisUserEmailSends = data[userId];
      if (thisUserEmailSends) {
        const sortedData = thisUserEmailSends.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setSortedTimeline(sortedData);
      }
    }
  }, [data, userId]);

  const counts = useMemo(() => {
    if (data) {
      const thisUserEmailSends = data[userId];
      if (thisUserEmailSends) {
        return {
          "user-guide": thisUserEmailSends.filter(
            (emailSend) => emailSend.emailCategory === "user-guide"
          ).length,
          "device-onboarding": thisUserEmailSends.filter(
            (emailSend) => emailSend.emailCategory === "device-onboarding"
          ).length,
          "employee-handbook": thisUserEmailSends.filter(
            (emailSend) => emailSend.emailCategory === "employee-handbook"
          ).length,
          "cs-training": thisUserEmailSends.filter(
            (emailSend) => emailSend.emailCategory === "cs-training"
          ).length,
        };
      }
    }
  }, [data, userId]);

  if (isError) {
    return (
      <Td textAlign="center">
        <Tag colorScheme="red">error</Tag>
      </Td>
    );
  }

  if (isFetching) {
    return (
      <Td textAlign="center">
        <CircularProgress isIndeterminate size="30px" color="gray.800" />
      </Td>
    );
  }

  return (
    <Td textAlign="center">
      <Popover>
        <PopoverTrigger>
          <IconButton
            variant="ghost"
            icon={<BsMailbox size="20px" />}
            aria-label="Show Timeline"
          />
        </PopoverTrigger>
        <PopoverContent width="400px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Email Timeline</PopoverHeader>
          <PopoverHeader>
            {counts ? (
              <Flex ml="10px" gap="10px" justifyContent="space-evenly">
                <Tooltip label="User Guide">
                  <Tag>
                    <Flex mr="10px">
                      <BsPerson />
                    </Flex>
                    {counts["user-guide"]}
                  </Tag>
                </Tooltip>
                <Tooltip label="Device Onboarding (default)">
                  <Tag>
                    <Flex mr="10px">
                      <BsLaptop />
                    </Flex>
                    {counts["device-onboarding"]}
                  </Tag>
                </Tooltip>
                <Tooltip label="Employee Handbook">
                  <Tag>
                    <Flex mr="10px">
                      <BsPen />
                    </Flex>
                    {counts["employee-handbook"]}
                  </Tag>
                </Tooltip>
                <Tooltip label="Training">
                  <Tag>
                    {" "}
                    <Flex mr="10px">
                      <BsLightbulb />
                    </Flex>
                    {counts["cs-training"]}
                  </Tag>
                </Tooltip>
              </Flex>
            ) : (
              <Text>No emails sent yet.</Text>
            )}
          </PopoverHeader>
          {sortedTimeline && (
            <PopoverBody>
              <Box maxH="200px" overflowY="scroll">
                <Table variant="simple" size="sm">
                  <Tbody>
                    {sortedTimeline?.map((emailSend) => (
                      <Tr key={emailSend.createdAt}>
                        <Td width="110px" padding="0px">
                          {new Date(emailSend.createdAt).toLocaleString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            }
                          )}
                        </Td>
                        <Td padding="10px">
                          <Tooltip label={emailSend.sentTo}>
                            <Box
                              maxWidth="100px"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {emailSend.sentTo}
                            </Box>
                          </Tooltip>
                        </Td>
                        <Td>
                          <Tooltip
                            label={emailSend.friendlyName}
                            isDisabled={
                              emailSend.friendlyName.length <= NAME_CHARS_SHOWN
                            } // only render if overflowing
                          >
                            <Box
                              width="120px"
                              textOverflow={
                                emailSend.friendlyName.length <=
                                NAME_CHARS_SHOWN
                                  ? "visible"
                                  : "hidden"
                              }
                              noOfLines={4}
                            >
                              {emailSend.friendlyName}
                            </Box>
                          </Tooltip>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </PopoverBody>
          )}
        </PopoverContent>
      </Popover>
    </Td>
  );
}
