import { InfoIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Td,
  Tooltip,
  Icon,
  Flex,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import {
  toastSuccess,
  toastError,
} from "../../../../../Providers/ToastProvider";
import { UserTypes } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.constants";
import { AegisUser } from "../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { TenantContext } from "../../../TenantContext";

export default function UserBasicInfo({
  aegisUser,
  error,
  toast,
}: Readonly<{
  aegisUser: AegisUser;
  error: Error | null;
  toast: ReturnType<typeof useToast>;
}>) {
  const { tenant } = useContext(TenantContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, emailDomain] = aegisUser.email.trim().toLowerCase().split("@");

  const handleCopyToClipboard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
      // Read clipboard text
      const clipText = await navigator.clipboard.readText();
      const editorElement: HTMLElement | null =
        document.querySelector(".editor");
      if (editorElement) {
        editorElement.innerText += clipText;
      }
      toastSuccess(toast, "Copied to clipboard");
    } catch (error) {
      toastError(toast, error);
    }
  };

  const emailProperties = useMemo(() => {
    if (!aegisUser.active) {
      return { notice: "(inactive)", color: "gray.400" };
    } else if (emailDomain !== tenant?.compliantDomain?.toLowerCase()) {
      return {
        notice: "(does not have the Atomus registered compliant domain)",
        color: "red",
      };
    } else if (aegisUser.userType === UserTypes.OFFLINE) {
      return { notice: "(offline user)", color: "black" };
    } else {
      return { notice: "", color: "black" };
    }
  }, [
    aegisUser.active,
    aegisUser.userType,
    emailDomain,
    tenant?.compliantDomain,
  ]);

  return (
    <>
      <Td>
        <Flex alignItems="center" gap="4px">
          <Tooltip
            label={`${aegisUser.displayName} ${
              (!aegisUser.active && "(inactive)") || ""
            }`}
          >
            <Text
              as="span"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              display="inline-block"
              maxWidth="120px"
              cursor="pointer"
              color={aegisUser.active ? "black" : "gray.400"}
            >
              {aegisUser.displayName}
            </Text>
          </Tooltip>
          {error && (
            <Tooltip label={error.message}>
              <Icon as={InfoIcon} color="red" />
            </Tooltip>
          )}
        </Flex>
      </Td>
      <Td>
        <Flex align="center">
          <Tooltip label={`${aegisUser.email} ${emailProperties.notice}`}>
            <Text
              as="span"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              display="inline-block"
              maxWidth="120px"
              cursor="pointer"
              color={emailProperties.color}
            >
              {aegisUser.email}
            </Text>
          </Tooltip>
          <Button
            size="sm"
            ml={2}
            onClick={() => handleCopyToClipboard(aegisUser.email)}
          >
            <Icon as={CopyIcon} />
          </Button>
        </Flex>
      </Td>
    </>
  );
}
