import { useMsal } from "@azure/msal-react";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenRequestParams } from "../types/auth.types";

interface CachedToken {
  expiresOn: Date;
  accessToken: string;
}

interface IMicrosoftTokenContext {
  getTokenForScopes: (
    scopes: string[],
    params?: TokenRequestParams
  ) => Promise<string>;
}

const MAX_RETRIES = 60;

const defaultValue: IMicrosoftTokenContext = {
  getTokenForScopes: () => {
    throw new Error("you must wrap your component in a MicrosoftTokenProvider");
  },
};

const MicrosoftTokenContext = createContext(defaultValue);

function getCacheKey(scopes: string[]): string {
  return JSON.stringify(scopes);
}

/**
 * @description verifies that the `expiresOn` value of a Microsoft auth result
 * is after 1 minute from the time of calling the function (1 minute buffer is
 * to avoid race conditions in request time).
 * @param expiresOn the expires on value
 */
function tokenIsExpired(expiresOn: Date | null): boolean {
  if (!expiresOn || expiresOn.getTime() < Date.now() + 60000) {
    return true;
  }
  return false;
}

export function useGetTokenForScopes() {
  const context = useContext(MicrosoftTokenContext);
  if (!context) {
    throw new Error("you must wrap your component in a MicrosoftTokenProvider");
  }
  return context.getTokenForScopes;
}

export function MicrosoftTokenProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const { instance } = useMsal();
  const authCache = useRef<Record<string, CachedToken>>({});
  const inProgress = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/login") {
      authCache.current = {};
      inProgress.current = false;
    }
  }, [location.pathname]);

  useEffect(() => {
    authCache.current = {};
    inProgress.current = false;
  }, [instance]);

  const addToCache = useCallback(
    (cacheKey: string, accessToken: string, expiresOn: Date): void => {
      if (tokenIsExpired(expiresOn)) {
        throw new Error("received an expired token from msal");
      }
      authCache.current[cacheKey] = { accessToken, expiresOn };
    },
    []
  );

  const confirmActiveAccount = useCallback((): boolean => {
    if (!instance.getActiveAccount()) {
      navigate("/login");
      return false;
    }
    return true;
  }, [instance, navigate]);

  const getCachedToken = useCallback(
    (
      cacheKey: string,
      forceInteractive: boolean | undefined
    ): string | null => {
      if (forceInteractive) {
        return null;
      }
      const cachedToken = authCache.current[cacheKey];
      if (!cachedToken || tokenIsExpired(cachedToken.expiresOn)) {
        delete authCache.current[cacheKey];
        return null;
      }
      return `Bearer ${cachedToken.accessToken}`;
    },
    []
  );

  const doInteractiveLogin = useCallback(
    async (cacheKey: string, scopes: string[]): Promise<string> => {
      try {
        const { accessToken, expiresOn } = await instance.acquireTokenPopup({
          scopes,
        });
        if (expiresOn) {
          addToCache(cacheKey, accessToken, expiresOn);
        }
        return `Bearer ${accessToken}`;
      } finally {
        inProgress.current = false;
      }
    },
    [addToCache, instance]
  );

  const doSilentFlow = useCallback(
    async (cacheKey: string, scopes: string[]): Promise<string> => {
      const { accessToken, expiresOn } = await instance.acquireTokenSilent({
        scopes,
      });
      if (expiresOn) {
        addToCache(cacheKey, accessToken, expiresOn);
      } else {
        console.warn("got an msal authentication result without expiresOn");
      }
      return `Bearer ${accessToken}`;
    },
    [addToCache, instance]
  );

  const getTokenForScopes = useCallback(
    async (scopes: string[], params?: TokenRequestParams): Promise<string> => {
      if (!confirmActiveAccount()) {
        return "";
      }
      const cacheKey = getCacheKey(scopes);
      const cachedToken = getCachedToken(cacheKey, params?.forceInteractive);
      if (cachedToken) {
        return cachedToken;
      }
      try {
        if (params?.forceInteractive) {
          throw new Error("forcing interactive login");
        }
        return await doSilentFlow(cacheKey, scopes);
      } catch (error) {
        if (params?.isRetry) {
          throw error;
        }
        for (let attempt = 1; attempt <= MAX_RETRIES; ++attempt) {
          if (!inProgress.current && attempt === 1) {
            inProgress.current = true;
            break;
          } else if (!inProgress.current) {
            return getTokenForScopes(scopes, {
              isRetry: true,
              forceInteractive: params?.forceInteractive,
            });
          } else if (attempt === MAX_RETRIES) {
            throw new Error(
              "timed out waiting for another interactive msal process"
            );
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        return doInteractiveLogin(cacheKey, scopes);
      }
    },
    [confirmActiveAccount, doInteractiveLogin, doSilentFlow, getCachedToken]
  );

  const value = useMemo<IMicrosoftTokenContext>(
    () => ({ getTokenForScopes }),
    [getTokenForScopes]
  );
  return (
    <MicrosoftTokenContext.Provider value={value}>
      {children}
    </MicrosoftTokenContext.Provider>
  );
}
