import { Flex, Td } from "@chakra-ui/react";
import UserMicrosoftOptions from "./UserAttributeOptions/UserMicrosoftOptions";
import UserDeviceOptions from "./UserAttributeOptions/UserDeviceOptions";
import UserAppleOptions from "./UserAttributeOptions/UserAppleOptions";
import { useOptionalFeatureStatus } from "../../../../../../hooks/tenants.hooks";
import UserMobileDeviceOptions from "./UserAttributeOptions/UserMobileDeviceOptions";
import { useContext } from "react";
import AuthContext from "../../../../../../contexts/AuthContext";

type IUserAttributesSectionProps = Readonly<{
  tenantId: string;
}>;

export default function UserAttributesSection({
  tenantId,
}: IUserAttributesSectionProps) {
  const { role } = useContext(AuthContext);
  const { data } = useOptionalFeatureStatus("duo:mfa", tenantId);
  const duoAvailable = !!data?.availableForTenant;

  return (
    <Td as={Flex} justify="center">
      <UserMicrosoftOptions tenantId={tenantId} />
      <UserAppleOptions tenantId={tenantId} />
      <UserDeviceOptions tenantId={tenantId} />
      {duoAvailable && role === "securityAdmin" && (
        <UserMobileDeviceOptions tenantId={tenantId} />
      )}
    </Td>
  );
}
