import { Skeleton, Tag, Td } from "@chakra-ui/react";
import { useMemo } from "react";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import { useDeviceAppRecommendations } from "../../../../hooks/devices.hooks";
import {
  Device,
  SecurityRecommendation,
} from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";

export default function AppRecommendationsValue({
  device,
  tenantId,
}: Readonly<{ device: Device; tenantId: string }>) {
  const { isLoading, data, error } = useDeviceAppRecommendations(tenantId);
  const thisDeviceRecommendations = useMemo<
    | Pick<
        SecurityRecommendation,
        "id" | "recommendationName" | "relatedComponent" | "remediationType"
      >[]
    | null
  >(() => {
    return data && device.deviceId && data[device.deviceId]
      ? data[device.deviceId]
      : null;
  }, [data, device.deviceId]);

  if (isLoading) {
    return (
      <Td>
        <Skeleton width="fit-content">
          <Tag>Not applicable</Tag>
        </Skeleton>
      </Td>
    );
  }

  if (thisDeviceRecommendations === null) {
    return (
      <Td>
        <TooltipTag
          tagText="Not found"
          tooltipText="This likely means the device has not been enrolled in defender"
          tagColorScheme="red"
        />
      </Td>
    );
  }

  if (error) {
    return (
      <Td>
        <TooltipTag
          tagText="Error"
          tooltipText={error.message}
          tagColorScheme="red"
        />
      </Td>
    );
  }

  return (
    <Td>
      {thisDeviceRecommendations && (
        <TooltipTag
          tagColorScheme={
            thisDeviceRecommendations.length === 0 ? "green" : "yellow"
          }
          tagText={`${thisDeviceRecommendations.length}`}
          tooltipText="Click for details"
          toolTipIsDisabled={thisDeviceRecommendations.length === 0}
        />
      )}
    </Td>
  );
}
