import { Progress, Center, Button, Flex } from "@chakra-ui/react";
import MsftInfoForm from "../MsftInfoForm/MsftInfoForm";
import Page from "../../../Components/PageContainer";
import { useTenants } from "../../../hooks/tenants.hooks";
import MappedTenants from "./MappedTenants";
import AuthRequiredTenants from "./AuthRequiredTenants";
import ErrorTenants from "./ErrorTenants";
import NoMsftAccessTenants from "./NoMsftAccessTenants";

export default function SecurityAdminTenantsPage() {
  const { data, error, isLoading, isError, isRefetching, refetch } =
    useTenants();

  return (
    <Page>
      {(isLoading || isRefetching) && (
        <Progress isIndeterminate borderRadius="md" />
      )}
      {isError && <Center>{error.message}</Center>}
      {!isLoading && !isRefetching && data && (
        <Flex flexDir="column" gap="24px">
          <MsftInfoForm
            companies={data.needMsftInfo}
            refreshTenants={refetch}
          />
          <MappedTenants mappedTenants={data.mappedTenants} />
          <AuthRequiredTenants
            authRequiredTenants={data.authRequiredTenants}
            refetchTenants={refetch}
          />
          <ErrorTenants errorTenants={data.errorTenants} />
          <NoMsftAccessTenants noMsftAccessTenants={data.noMsftAccessTenants} />
          <Center>
            <Button
              colorScheme="blue"
              variant="ghost"
              mt={8}
              onClick={() => refetch({ throwOnError: true })}
            >
              Refresh
            </Button>
          </Center>
        </Flex>
      )}
    </Page>
  );
}
