import { Flex, Checkbox, Collapse } from "@chakra-ui/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { UserTableContext } from "../../UserTableContext";
import OnboardingOptionCheckbox from "./CustomEmailOptions/OnboardingOptionCheckbox";

export default function CustomEmailOptions() {
  const [macIsChecked, setMacIsChecked] = useState(false);
  const [windowsIsChecked, setWindowsIsChecked] = useState(false);
  const { selectedEmails, setSelectedEmails } = useContext(UserTableContext);

  const handleToggleWindows = () => {
    if (windowsIsChecked) {
      // uncheck all windows
      setSelectedEmails((currentSelection) =>
        currentSelection.filter(
          (emailCategory) => !emailCategory.startsWith("custom-windows")
        )
      );
    }
    // toggle
    setWindowsIsChecked((current) => !current);
  };

  const handleToggleMac = () => {
    if (macIsChecked) {
      // uncheck all macos
      setSelectedEmails((currentSelection) =>
        currentSelection.filter(
          (emailCategory) => !emailCategory.startsWith("custom-macos")
        )
      );
    }
    // toggle
    setMacIsChecked((current) => !current);
  };

  const canCheckCredentials = useMemo(() => {
    return selectedEmails.some(
      (emailCategory) =>
        emailCategory.startsWith("custom") &&
        !["custom-apple-credentials", "custom-msft-credentials"].includes(
          emailCategory
        )
    );
  }, [selectedEmails]);

  useEffect(() => {
    if (!canCheckCredentials) {
      setSelectedEmails((curr) =>
        curr.filter(
          (category) =>
            !["custom-apple-credentials", "custom-msft-credentials"].includes(
              category
            )
        )
      );
    }
  }, [canCheckCredentials, setSelectedEmails]);

  return (
    <Flex flexDir="column" gap="4px">
      <Checkbox
        isChecked={windowsIsChecked}
        onChange={() => handleToggleWindows()}
      >
        Windows
      </Checkbox>
      <Collapse in={windowsIsChecked} unmountOnExit>
        <Flex flexDir="column" gap="4px" paddingLeft="24px">
          <OnboardingOptionCheckbox
            description="Partition"
            emailCategory="custom-windows-partition"
          />
          <OnboardingOptionCheckbox
            description="Switch user"
            emailCategory="custom-windows-switch-user"
          />
          <OnboardingOptionCheckbox
            description="New device"
            emailCategory="custom-windows-new-device"
          />
          <OnboardingOptionCheckbox
            description="App install only"
            emailCategory="custom-windows-app-only"
          />
        </Flex>
      </Collapse>
      <Checkbox isChecked={macIsChecked} onChange={() => handleToggleMac()}>
        Mac
      </Checkbox>
      <Collapse in={macIsChecked} unmountOnExit>
        <Flex flexDir="column" gap="4px" paddingLeft="24px">
          <OnboardingOptionCheckbox
            description="Partition"
            emailCategory="custom-macos-partition"
          />
          <OnboardingOptionCheckbox
            description="New device"
            emailCategory="custom-macos-new-device"
          />
          <OnboardingOptionCheckbox
            description="Include Apple Temporary Password"
            emailCategory="custom-apple-credentials"
            isDisabled={!canCheckCredentials}
          />
        </Flex>
      </Collapse>
      <OnboardingOptionCheckbox
        description="Ubuntu (v20.04 and v22.04 supported)"
        emailCategory="custom-ubuntu"
      />
      <OnboardingOptionCheckbox description="iOS" emailCategory="custom-ios" />
      <OnboardingOptionCheckbox
        description="Android"
        emailCategory="custom-android"
      />
      <OnboardingOptionCheckbox
        description="Include MSFT Temporary Password"
        emailCategory="custom-msft-credentials"
        isDisabled={!canCheckCredentials}
      />
    </Flex>
  );
}
