import { Tag } from "@chakra-ui/react";

interface IInfoFieldProps {
  data: any;
  colorScheme?: string;
}

/**
 * @description simple function that returns data if it exists, otherwise a
 * red tag with "unknown" as the text indicating data should exist
 * @param data data to render
 */
export default function InfoField({
  data,
  colorScheme,
}: Readonly<IInfoFieldProps>) {
  let thisColorScheme = colorScheme;
  if (!thisColorScheme) {
    thisColorScheme = data ? "gray" : "red";
  }
  return (
    <Tag colorScheme={thisColorScheme} mt="5px">
      {data === null || data === undefined ? "unknown" : data}
    </Tag>
  );
}
