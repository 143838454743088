import { useQuery, UseQueryResult } from "react-query";
import {
  getAegisUsers,
  getDevicesForAegisUsers,
  getAzureUserInfo,
  getEmailTimelines,
  getExternalAtomusUsers,
  getUsersReadyToOnboard,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AegisUser,
  AzureUserInfo,
  DbDeviceRowFields,
  EmailTimelines,
  ExternalAzureUserWithId,
  ReadyToOnboardStatuses,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";
import useMicrosoftToken from "./tokens.hooks";

export function useAegisUsers(
  tenantId: string
): UseQueryResult<AegisUser[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<AegisUser[], Error>(
    ["users", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getAegisUsers(token, tenantId);
    }
  );
  return usersQuery;
}

export function useAegisUsersDevices(
  tenantId: string
): UseQueryResult<Record<string, DbDeviceRowFields[]>, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<Record<string, DbDeviceRowFields[]>, Error>(
    ["users-devices", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getDevicesForAegisUsers(token, tenantId);
    }
  );
  return usersQuery;
}

export function useAzureUserInfo(
  tenantId: string,
  userId?: string
): UseQueryResult<AzureUserInfo | null, Error> {
  const { data: userData } = useAegisUsers(tenantId);
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<AzureUserInfo | null, Error>(
    ["msftUsers", tenantId, userId],
    async () => {
      if (userId) {
        const token = await getInternalApiToken();
        const res = await getAzureUserInfo(token, tenantId, userId);
        return res;
      } else {
        return null;
      }
    },
    { enabled: Boolean(userData) }
  );
  return usersQuery;
}

export function useCompanyDeviceOnboardingChecks(
  tenantId: string
): UseQueryResult<ReadyToOnboardStatuses, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  return useQuery<ReadyToOnboardStatuses, Error>(
    ["ready-to-onboard", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return getUsersReadyToOnboard(token, tenantId);
    }
  );
}

export function useExternalAtomusUsers(
  tenantId: string
): UseQueryResult<ExternalAzureUserWithId[], Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<ExternalAzureUserWithId[], Error>(
    ["externalAtomusUsers", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return await getExternalAtomusUsers(token, tenantId);
    },
    { refetchOnMount: true }
  );
  return usersQuery;
}

export function useEmailTimeLines(
  tenantId: string
): UseQueryResult<EmailTimelines, Error> {
  const { getInternalApiToken } = useMicrosoftToken();
  const usersQuery = useQuery<EmailTimelines, Error>(
    ["emailTimelines", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return await getEmailTimelines(token, tenantId);
    },
    { refetchOnMount: true }
  );
  return usersQuery;
}
