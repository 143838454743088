import { HStack } from "@chakra-ui/react";
import ArcSection from "./ArcSection";
import DefenderSection from "./DefenderSection";
import DnsSection from "./DnsSection";
import VpnSection from "./VpnSection";
import DocumentationLinkSection from "./DocumentationLinkSection";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";

interface ISubHeaderProps {
  tenantId: string;
}

export default function SubHeader({ tenantId }: ISubHeaderProps) {
  const { role } = useContext(AuthContext);
  return (
    <HStack mb={4} spacing={10} alignItems="baseline">
      <DnsSection tenantId={tenantId} />
      <VpnSection tenantId={tenantId} />
      <ArcSection tenantId={tenantId} />
      <DefenderSection tenantId={tenantId} />
      {role === "securityAdmin" && <DocumentationLinkSection />}
    </HStack>
  );
}
