import { Flex, HStack, Stat, StatLabel } from "@chakra-ui/react";
import InfoField from "../../../../Components/InfoField";
import { Device } from "../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { NOT_APPLICABLE_STR } from "../../../../services/atomus-internal-apis/atomus-internal-apis.constants";

interface IDetailsHeaderProps {
  device: Device;
}

function getEnrollmentTypeColorScheme(enrollmentType: string | null) {
  if (enrollmentType !== null) {
    if (enrollmentType === NOT_APPLICABLE_STR) {
      return "gray";
    } else {
      return "green";
    }
  } else {
    return "red";
  }
}

export default function StatSection({ device }: Readonly<IDetailsHeaderProps>) {
  const getLastSignedInDate = (date?: Date) => {
    if (!date) {
      // unable to parse date string
      return "n/a";
    } else {
      return date.toLocaleString();
    }
  };

  return (
    <>
      <Flex flexDir="column" gap="12px">
        <HStack alignItems="flex-start" spacing="4px" mx="10px">
          <Stat>
            <StatLabel>Status</StatLabel>
            <InfoField
              colorScheme={device.accountEnabled ? "green" : "yellow"}
              data={device.accountEnabled ? "Enabled" : "Disabled"}
            />
          </Stat>
          {device.approximateLastSignInDateTime ? (
            <Stat>
              <StatLabel>Last Signed In</StatLabel>
              <InfoField
                data={getLastSignedInDate(
                  new Date(device.approximateLastSignInDateTime)
                )}
              />
            </Stat>
          ) : (
            <Stat></Stat>
          )}

          <Stat></Stat>
          <Stat></Stat>
        </HStack>
      </Flex>
      <Flex flexDir="column" gap="12px" mt="20px">
        <HStack alignItems="flex-start" spacing="4px" mx="10px">
          <Stat>
            <StatLabel>Operating System</StatLabel>
            <InfoField data={device.operatingSystem} />
          </Stat>
          {device.operatingSystem !== "Linux" ? (
            <>
              <Stat>
                <StatLabel>Enrollment Type</StatLabel>
                <InfoField
                  data={device.enrollmentType}
                  colorScheme={getEnrollmentTypeColorScheme(
                    device.enrollmentType
                  )}
                />
              </Stat>
              <Stat>
                <StatLabel>Management Type</StatLabel>
                <InfoField data={device.managementType} />
              </Stat>
              <Stat></Stat>
            </>
          ) : (
            <>
              <Stat></Stat>
              <Stat></Stat>
              <Stat></Stat>
            </>
          )}
        </HStack>
      </Flex>
    </>
  );
}
