export const MsBaseUrls = {
  commercial: {
    AZ_PORTAL: "https://portal.azure.com",
    LOGIN: "https://login.microsoftonline.com",
  },
  gov: {
    AZ_PORTAL: "https://portal.azure.us",
    LOGIN: "https://login.microsoftonline.us",
  },
} as const;

export const INTUNE_MDM_APP_ID = "0000000a-0000-0000-c000-000000000000";

export const IntuneDeviceOs = {
  MAC: "MacMDM",
  WINDOWS: "Windows",
} as const;

export const IntuneEnrollmentTypes = {
  DOMAIN_JOINED: "AzureDomainJoined",
  USER_ENROLLMENT: "UserEnrollment",
};

export const INTUNE_MANAGEMENT_TYPE = "MDM";

export const DeviceTrustTypes = {
  WORKPLACE: "Workplace",
  AZURE_AD: "AzureAd",
  SERVER_AD: "ServerAd",
} as const;
