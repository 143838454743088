import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Tooltip,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import LoadingBar from "../../../../../../../../../Components/LoadingBar";
import {
  AegisUser,
  CheckrCandidate,
} from "../../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { useContext, useEffect, useState } from "react";
import { UserTableContext } from "../../../../../UserTableContext";
import { useCheckrCandidates } from "../../../../../../../../../hooks/integrations.hooks";
import useMicrosoftToken from "../../../../../../../../../hooks/tokens.hooks";
import { useAegisUsers } from "../../../../../../../../../hooks/users.hooks";
import { mapCheckrCandidate } from "../../../../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import { useTenantId } from "../../../../../../../../../hooks/utils.hooks";
import { toastError } from "../../../../../../../../../Providers/ToastProvider";

interface ICandidatesModalProps {
  aegisUser: AegisUser;
  isOpen: boolean;
  onClose: () => void;
}

export default function CheckrCandidatesModal({
  aegisUser,
  isOpen,
  onClose,
}: Readonly<ICandidatesModalProps>) {
  const { checkrCandidatesIsLoading, checkrCandidates } =
    useContext(UserTableContext);

  const [selectedCandidate, setSelectedCandidate] = useState<string | null>(
    null
  );
  const tenantId = useTenantId();
  const { refetch: checkrCandidatesRefetch } = useCheckrCandidates(tenantId);
  const { refetch: usersRefetch } = useAegisUsers(tenantId);
  const { getInternalApiToken } = useMicrosoftToken();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastProvider = useToast();
  const { isError } = useCheckrCandidates(tenantId);

  const handleSelect = (checkrId: string) => {
    setSelectedCandidate(selectedCandidate === checkrId ? null : checkrId);
  };

  const handleSubmitMapping = async () => {
    if (selectedCandidate) {
      setIsLoading(true);
      const token = await getInternalApiToken();
      await mapCheckrCandidate(
        token,
        tenantId,
        aegisUser.id,
        selectedCandidate
      );
      await Promise.all([usersRefetch(), checkrCandidatesRefetch()]);
      setIsLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    if (isError && isOpen) {
      toastError(toastProvider, "Error loading Checkr candidates");
      onClose();
    }
  }, [isError, toastProvider, onClose, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Map Checkr Candidate to ${aegisUser.displayName}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflow="scroll" maxH="500px">
          {checkrCandidatesIsLoading ? (
            <LoadingBar />
          ) : (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th width="160px">First Name</Th>
                  <Th width="160px">Last Name</Th>
                  <Th width="200px">Email</Th>
                </Tr>
              </Thead>
              <Tbody>
                {checkrCandidates.map((candidate: CheckrCandidate) => (
                  <Tr
                    key={candidate.checkrId}
                    onClick={() => handleSelect(candidate.checkrId)}
                    cursor="pointer"
                  >
                    <Td width="10px" onClick={(e) => e.stopPropagation()}>
                      <Checkbox
                        isChecked={selectedCandidate === candidate.checkrId}
                        onChange={() => handleSelect(candidate.checkrId)}
                      />
                    </Td>
                    <Td width="10px">{candidate.firstName}</Td>
                    <Td width="10px">{candidate.lastName}</Td>
                    <Td>
                      <Tooltip
                        label={
                          candidate.email.length > 20 ? candidate.email : ""
                        }
                        aria-label="Checkr candidate email"
                      >
                        {`${candidate.email.slice(0, 20)}${
                          candidate.email.length > 20 ? "..." : ""
                        }`}
                      </Tooltip>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            mr="10px"
            colorScheme="blue"
            onClick={() => handleSubmitMapping()}
            isDisabled={selectedCandidate === null}
            isLoading={isLoading}
          >
            Submit
          </Button>
          <Button colorScheme="red" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
