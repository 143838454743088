import { Box, Skeleton, Tag } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TooltipTag from "../../../../Components/TooltipTag/TooltipTag";
import { useBackupHealthcheck } from "../../../../hooks/devices.hooks";

export default function LastBackupValue({
  deviceId,
  deviceName,
  tenantId,
}: {
  deviceId: string;
  deviceName: string;
  tenantId: string;
}) {
  const { isFetching, isError, error, data, isIdle } = useBackupHealthcheck(
    tenantId,
    deviceName
  );
  const [tagColor, setTagColor] = useState<string>("gray");
  const [oldestSnapshot, setOldestSnapshot] = useState<Date | null>(null);
  const [colorError, setColorError] = useState<string | null>(null);

  useEffect(() => {
    if (!data) {
      return;
    }
    setColorError(null);
    if (
      data.totalTargets <= 0 ||
      data.existingSnapshots !== data.totalTargets
    ) {
      setTagColor("red");
      return;
    }
    if (!data.oldestSnapshot) {
      setTagColor("red");
      return;
    }
    try {
      const now = new Date();
      const snapshotDate = new Date(data.oldestSnapshot);
      setOldestSnapshot(snapshotDate);
      const weekOld = new Date(snapshotDate);
      weekOld.setDate(weekOld.getDate() + 7);
      if (now.valueOf() <= weekOld.valueOf()) {
        setTagColor("green");
        return;
      }
      const monthOld = new Date(snapshotDate);
      monthOld.setDate(monthOld.getDate() + 30);
      if (now.valueOf() <= monthOld.valueOf()) {
        setTagColor("yellow");
        return;
      }
      setTagColor("red");
    } catch (error) {
      setTagColor("gray");
      setColorError("Error parsing snapshot date.");
      console.error(error);
    }
  }, [data]);

  if (colorError) {
    return (
      <TooltipTag
        tagText="Error"
        tooltipText={colorError}
        tagColorScheme="red"
      />
    );
  }

  return (
    <Skeleton isLoaded={!isFetching && !isIdle}>
      {isError && (
        <TooltipTag
          tagText="Error"
          tooltipText={error.message}
          tagColorScheme="red"
        />
      )}
      {!isError && !data && (
        <TooltipTag
          tagText="Not found"
          tooltipText="No backup healthcheck data."
          tagColorScheme="red"
        />
      )}
      {data && !oldestSnapshot && (
        <TooltipTag
          tagText="Not found"
          tooltipText="No complete backups found."
          tagColorScheme="red"
        />
      )}
      {data && oldestSnapshot && (
        <Box>
          <Tag colorScheme={tagColor}>
            {new Date(oldestSnapshot).toDateString()}
          </Tag>
        </Box>
      )}
    </Skeleton>
  );
}
