import { useMsal } from "@azure/msal-react";
import {
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import useMicrosoftToken from "../../../hooks/tokens.hooks";
import { AtomusTenant } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";

export default function AuthRequiredTenants({
  authRequiredTenants,
  refetchTenants,
}: Readonly<{
  authRequiredTenants: AtomusTenant[];
  refetchTenants: () => void;
}>) {
  const { instance } = useMsal();
  const { getInternalApiToken } = useMicrosoftToken();

  if (authRequiredTenants.length === 0) {
    return <></>;
  }

  const handleAuth = async (tenant: AtomusTenant) => {
    const config = instance.getConfiguration();
    // since config is a reference to the instance's config (i checked the source
    // code and it does not copy the value in getConfiguration) this updates the
    // instances tenant id
    config.auth.azureCloudOptions.tenant = tenant.tenantId;
    // with the updated tenant id forcing an interactive login now will prompt the
    // user for the MFA they have setup for the tenant
    await getInternalApiToken({ forceInteractive: true });
    refetchTenants();
  };

  return (
    <Flex flexDir="column" gap="8px">
      <Heading size="md">Companies that need MFA</Heading>
      <Table>
        <Thead>
          <Th>Company name</Th>
          <Th>Shortform</Th>
        </Thead>
        <Tbody>
          {[...authRequiredTenants]
            .sort((a, b) =>
              a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            )
            .map((tenant) => (
              <Tr
                key={tenant.shortform}
                _hover={{ cursor: "pointer", backgroundColor: "gray.200" }}
                onClick={() => handleAuth(tenant)}
              >
                <Td>{tenant.name}</Td>
                <Td>{tenant.shortform}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Flex>
  );
}
