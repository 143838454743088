import { Flex, Heading } from "@chakra-ui/react";
import { Column } from "react-table";
import SortableTable from "../../../Components/SortableTable";
import { TenantListResponse } from "../../../services/atomus-internal-apis/atomus-internal-apis.types";

const COLUMNS: Column<TenantListResponse["errorTenants"][number]>[] = [
  {
    Header: "Company name",
    accessor: "name",
  },
  {
    Header: "Shortform",
    accessor: "shortform",
  },
  {
    Header: "Error message",
    accessor: "error",
  },
];

export default function ErrorTenants({
  errorTenants,
}: Readonly<{ errorTenants: TenantListResponse["errorTenants"] }>) {
  if (errorTenants.length === 0) {
    return <></>;
  }

  return (
    <Flex flexDir="column" gap="8px">
      <Heading size="md">Tenants with errors</Heading>
      <SortableTable
        tableProps={{
          columns: COLUMNS,
          data: errorTenants,
          initialState: { sortBy: [{ id: "name", desc: false }] },
        }}
      />
    </Flex>
  );
}
