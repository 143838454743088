import {
  Popover,
  PopoverTrigger,
  IconButton,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Button,
  Box,
  Flex,
  Tooltip,
  InputGroup,
  InputLeftAddon,
  Input,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { BsPhoneVibrate } from "react-icons/bs";
import { DuoSmsActivationRequestBody } from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.types";
import { sendDuoActivationTextAndEmail } from "../../../../../../../services/atomus-internal-apis/atomus-internal-apis.service";
import useMicrosoftToken from "../../../../../../../hooks/tokens.hooks";
import EmailSelector from "../../../../../../../Components/EmailSelector";
import { useContext, useEffect, useState } from "react";
import { UserRowContext } from "../../UserRowContext";
import {
  useAzureUserInfo,
  useEmailTimeLines,
} from "../../../../../../../hooks/users.hooks";
import {
  toastError,
  toastSuccess,
} from "../../../../../../../Providers/ToastProvider";

type IUserMobileDeviceOptions = Readonly<{
  tenantId: string;
}>;

export default function UserMobileDeviceOptions({
  tenantId,
}: IUserMobileDeviceOptions) {
  const { aegisUser } = useContext(UserRowContext);
  const { data } = useAzureUserInfo(tenantId, aegisUser?.id);
  const { refetch } = useEmailTimeLines(tenantId);

  const [selectedEmail, setSelectedEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const toastProvider = useToast();
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { getInternalApiToken } = useMicrosoftToken();

  const handleSendDuoActivationRequest = async () => {
    const token = await getInternalApiToken();
    setIsLoading(true);
    if (phone && selectedEmail && aegisUser?.id) {
      const reqBody: DuoSmsActivationRequestBody = {
        email: selectedEmail,
        phone: `1${phone}`,
        userId: aegisUser.id,
      };
      try {
        await sendDuoActivationTextAndEmail(token, tenantId, reqBody);
        onClose();
        toastSuccess(toastProvider, "Sent Duo activation instructions");
        refetch();
      } catch (error) {
        if ((error as Error).message?.includes("invalid phone number")) {
          toastError(
            toastProvider,
            "Cannot send Duo activation instructions to this phone number."
          );
        } else {
          toastError(
            toastProvider,
            "Error sending Duo activation instructions."
          );
        }
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (aegisUser?.email) {
      setSelectedEmail(aegisUser.email);
    }
  }, [aegisUser?.email]);

  return (
    <Popover onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <IconButton
          onClick={onOpen}
          paddingRight="3px"
          aria-label={"credentials"}
          variant="ghost"
          icon={<BsPhoneVibrate />}
        />
      </PopoverTrigger>
      <PopoverContent padding="10px" width="320">
        <PopoverArrow />
        <PopoverCloseButton m="5px" />
        <PopoverBody width="320px">
          <Box width="220px" overflow="hidden">
            <Text
              align="left"
              fontSize="12pt"
              ml="15px"
              mb="10px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              Send Duo MFA Enrollment instructions
            </Text>
          </Box>
          <Box
            justifySelf="center"
            minHeight="30px"
            maxHeight="200px"
            overflow="scroll"
            width="90%"
            border="2px solid"
            borderColor="blue.500"
            p="5px"
            rounded="7px"
          >
            <EmailSelector
              emails={[aegisUser?.email, data?.userPrincipalName]}
              onChange={(e) => setSelectedEmail(e.target.value)}
              width="100%"
            />
            <InputGroup mt="10px" width="100%">
              <InputLeftAddon paddingX="8px" height="40px">
                +1
              </InputLeftAddon>
              <Input
                placeholder="Mobile phone"
                paddingLeft="10px"
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setPhone(numericValue);
                  }
                }}
                onPaste={(e) => {
                  const copiedText = e.clipboardData.getData("Text");
                  const numericValue = copiedText
                    .replace(/\D/g, "")
                    .slice(0, 10);
                  setPhone(numericValue);
                }}
              />
            </InputGroup>
          </Box>
          <Flex flexDir="row-reverse" mr="10px" mt="5px">
            <Tooltip label={""}>
              <Button
                m="5px"
                colorScheme="blue"
                isDisabled={phone?.length !== 10 || !selectedEmail}
                isLoading={isLoading}
                onClick={handleSendDuoActivationRequest}
              >
                Send
              </Button>
            </Tooltip>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
