import { useQuery } from "react-query";
import { DeviceOSToSupportedPlatform } from "../services/atomus-internal-apis/atomus-internal-apis.constants";
import {
  getDeviceAppRecommendations,
  getDeviceHealthcheck,
  listDbDevices,
  listDevices,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";
import {
  AppInfo,
  BackupHealthcheckOutput,
  Device,
  HealthcheckRow,
  SecurityRecommendation,
  SupportedPlatform,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";
import { DeviceAndTenantAegisVersions } from "../types/types";
import {
  filterAegisDevices,
  filterIncorrectlyEnrolledDevices,
} from "../utils/devices.utils";
import { useTenantAppInfo } from "./tenants.hooks";
import useMicrosoftToken from "./tokens.hooks";
import { useAegisUsers } from "./users.hooks";

async function fetchDevices(token: string, tenantId: string) {
  const [devices, linuxDevices] = await Promise.all([
    listDevices(token, tenantId),
    listDbDevices(token, tenantId),
  ]);
  linuxDevices.forEach((device) => (device.accountEnabled = true));
  const allDevices = devices.concat(linuxDevices);
  return allDevices.sort((a, b) => {
    const key1 = a.displayName.toLowerCase();
    const key2 = b.displayName.toLowerCase();
    if (key1 < key2) {
      return -1;
    }
    if (key1 > key2) {
      return 1;
    }
    return 0;
  });
}

export function useDevices(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const devicesQuery = useQuery<Device[], Error>(
    ["devices", tenantId],
    async () => {
      const token = await getInternalApiToken();
      return fetchDevices(token, tenantId);
    }
  );

  return devicesQuery;
}

export function useIncorrectlyEnrolledDevices(tenantId: string) {
  const {
    data: deviceData,
    isFetching: devicesFetching,
    error: deviceError,
  } = useDevices(tenantId);
  const {
    data: usersData,
    isFetching: usersFetching,
    error: usersError,
  } = useAegisUsers(tenantId);
  const query = useQuery<Device[], Error>(
    ["devices", "unenrolled", tenantId],
    async () => {
      if (deviceError) {
        throw new Error(`dependent devices query error: ${deviceError}`);
      }
      if (!deviceData) {
        throw new Error("dependent devices data is undefined");
      }
      if (usersError) {
        throw new Error(`dependent users query error: ${usersError}`);
      }
      if (!usersData) {
        throw new Error("dependent users data is undefined");
      }
      const aegisDevices = filterAegisDevices(deviceData, usersData);
      return filterIncorrectlyEnrolledDevices(aegisDevices);
    },
    { enabled: !devicesFetching && !usersFetching }
  );
  return query;
}

export function useTenantDevicesHealthchecks(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const deviceDetailsQuery = useQuery<HealthcheckRow[] | null, Error>(
    ["devices", tenantId, "healthcheck"],
    async () => {
      const token = await getInternalApiToken();
      const hcData = await getDeviceHealthcheck(token, tenantId);
      return hcData
        ? hcData.sort((a, b) => {
            if (a.id_g !== b.id_g) {
              // prioritize sort by id_g
              return a.id_g.toLowerCase() < b.id_g.toLowerCase() ? -1 : 1;
            } else {
              // sort by stepName if id_g matches
              return a.stepName_s.toLowerCase() < b.stepName_s.toLowerCase()
                ? -1
                : 1;
            }
          })
        : null;
    }
  );
  return deviceDetailsQuery;
}

export function useBackupHealthcheck(tenantId: string, deviceName: string) {
  const hcQuery = useTenantDevicesHealthchecks(tenantId);
  return useQuery<BackupHealthcheckOutput | null, Error>(
    ["devices", tenantId, deviceName, "last-backup"],
    async () => {
      const backupHealthcheck = hcQuery.data?.find(
        (row) =>
          row.stepName_s === "last-backup" &&
          row.namesArray.includes(deviceName.toLowerCase())
      );
      if (!backupHealthcheck) {
        return null;
      }
      const parsed = JSON.parse(backupHealthcheck.data_s);
      if (parsed.error) {
        throw new Error("Healthcheck data contains an error.");
      }
      if (!parsed.output) {
        throw new Error("Healthcheck data does not have output.");
      }
      const output = parsed.output;
      if (typeof output !== "object" || Array.isArray(output)) {
        throw new Error("Invalid healthcheck data.");
      }
      const {
        existingSnapshots,
        totalTargets,
        oldestSnapshot,
        lastMaintenanceTime,
        maintenanceHasRun,
      } = output;
      if (
        [existingSnapshots, totalTargets].some(
          (val) => typeof val !== "number"
        ) ||
        [oldestSnapshot, lastMaintenanceTime, maintenanceHasRun].some(
          (val) => typeof val !== "string"
        )
      ) {
        throw new Error("Invalid healthcheck output.");
      }
      return {
        existingSnapshots,
        totalTargets,
        lastMaintenanceTime,
        maintenanceHasRun,
        oldestSnapshot,
      };
    },
    {
      enabled: Boolean(hcQuery.data),
    }
  );
}

export function useAegisVersionWithTenantVersion(
  tenantId: string,
  deviceName: string,
  deviceOs: string
) {
  const deviceHealthcheckQuery = useTenantDevicesHealthchecks(tenantId);
  const tenantVersionQuery = useTenantAppInfo(tenantId);

  return useQuery<DeviceAndTenantAegisVersions | null, Error>(
    ["devices", tenantId, deviceName, "version-with-tenant"],
    async () => {
      if (deviceHealthcheckQuery.error) {
        throw new Error(
          `dependent healthcheck query error: ${deviceHealthcheckQuery.error}`
        );
      }
      let tenantVersion: string | null = null;
      if (!DeviceOSToSupportedPlatform[deviceOs]) {
        throw new Error(`Invalid device OS: ${deviceOs}`);
      }
      const platform: SupportedPlatform = DeviceOSToSupportedPlatform[deviceOs];
      if (tenantVersionQuery.data![platform].success) {
        tenantVersion = (tenantVersionQuery.data![platform].data as AppInfo)
          .version;
      }
      if (
        deviceHealthcheckQuery.data === null ||
        deviceHealthcheckQuery.data!.length === 0
      ) {
        return null;
      }
      return {
        deviceVersion:
          deviceHealthcheckQuery.data?.find((hcRow) =>
            hcRow.namesArray.includes(deviceName.toLowerCase())
          )?.aegisVersion_s ?? null,
        tenantVersion,
      };
    },
    {
      enabled: !deviceHealthcheckQuery.isFetching,
    }
  );
}

export function useDeviceAppRecommendations(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();

  return useQuery<
    Record<
      string,
      Pick<
        SecurityRecommendation,
        "id" | "recommendationName" | "relatedComponent" | "remediationType"
      >[]
    >,
    Error
  >(["devices", tenantId, "device-recommendations"], async () => {
    const token = await getInternalApiToken();
    return await getDeviceAppRecommendations(token, tenantId);
  });
}
