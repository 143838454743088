import { Select } from "@chakra-ui/react";

export default function EmailSelector({
  emails,
  onChange,
  width = "340px",
}: Readonly<{
  emails: (string | undefined)[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  width?: string;
}>) {
  // lowercase all emails and get unique ones
  const uniqueEmails = Array.from(
    new Set(
      emails
        .filter((email) => email !== undefined)
        .map((email) => email?.toLowerCase())
    )
  );
  return (
    <Select
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      width={width}
      fontSize="12pt"
      placeholder=""
      onChange={onChange}
    >
      <option value={""} disabled>
        Select Email
      </option>
      {uniqueEmails.map((email) => (
        <option value={email} key={email}>
          {email}
        </option>
      ))}
    </Select>
  );
}
